import { GameEvent } from "../event/game-event.entity";
import { GameObject, Hitbox } from "../objects/game-object/game-object.entity";
import { InputService } from "../input.service";
import { Timer } from "../timer/timer";
import { AvifService } from "../avif.service";

export class Scene {
    public readonly id: number;
    public readonly name: string;
    public readonly gameObjects: GameObject[];
    public readonly backgroundImage: string;
    public readonly files: string[];
    public readonly hints: GameObject[];
    public readonly inputService?: InputService;
    private initialFocus: string[] | string = [];
    public position!: Hitbox;
    public fullscreen: boolean = true;
    private _timer: Timer = new Timer(1000);
    get timer() { return this._timer };
    private hasSetTargetInput = false;

    constructor(
        data :{
            scene: {
                id: number,
                name: string,
                backgroundImage: string
            },
            gameObjects: any[],
        },
        sourceEvent?: GameEvent,
        inputService?: InputService,
    ){
        this.id = data.scene.id;
        this.name = data.scene.name;
        this.gameObjects = data.gameObjects.filter((obj) => obj.objectType !== 'hint').map((obj) => new GameObject(Object.assign(obj, {sceneId: this.id}) as any));
        this.hints = data.gameObjects.filter((obj) => obj.objectType === 'hint').map((obj) => new GameObject(Object.assign(obj, {sceneId: this.id}) as any));
        this.backgroundImage = data.scene.backgroundImage;
        if (this.backgroundImage && AvifService.isAvifSupported())
            this.backgroundImage = AvifService.toAvif(this.backgroundImage);
        this.setAttributes(sourceEvent);
        this.files = this.gatherFiles();
        this.inputService = inputService;
    }

    setAttributes(sourceEvent?: GameEvent){
        this.setPosition(sourceEvent);
        if (!sourceEvent) return;
        const inputField = this.gameObjects.find((obj) => obj.type === 'inputField');
        if (sourceEvent['solutions'] && inputField)
            inputField['solutions'] = typeof sourceEvent['solutions'] === 'string' ? [sourceEvent['solutions']] : sourceEvent['solutions'];
        if (sourceEvent['alphabet'] && inputField)
            inputField['alphabet'] = sourceEvent['alphabet'];
        if (sourceEvent['forInputs']){
            this.initialFocus = sourceEvent['forInputs']
            this.setTargetInput()
        }
        if (sourceEvent['delay'])
            this.gameObjects.forEach((gameObject) => gameObject['delay'] = sourceEvent['delay']);
    }

    private setTargetInput(){
        const ids = typeof this.initialFocus === 'string' ? [Number(this.initialFocus)] : this.initialFocus.map((id: string) => Number(id));
        if (ids.length === 0) 
            return this.inputService?.setFocus([]);
        this.inputService?.setFocus(ids);
        this.hasSetTargetInput = true;
    }

    clearTargetInput(){
        if (!this.hasSetTargetInput || !this.inputService) return;
        this.inputService.resetFocus();
    }

    private gatherFiles(){
        const files = [];
        if (this.backgroundImage)
            files.push(this.backgroundImage);
        for (let gameObjectFiles of this.gameObjects.map((gameObject) => gameObject.files)){
            if (gameObjectFiles && gameObjectFiles.length > 0)
                files.push(...gameObjectFiles);
        }
        const uniqueFiles = [...new Set(files)];
        return AvifService.isAvifSupported() ? AvifService.toAvifMany(uniqueFiles) : uniqueFiles;
    }
    
    private setPosition(sourceEvent?: GameEvent): Hitbox{
        if (!sourceEvent || !(sourceEvent['top'] && sourceEvent['left'] && sourceEvent['width'] && sourceEvent['height']))
            return this.position = new Hitbox({x: 0, y: 0, width: 100,height: 100});
        
        this.fullscreen = false;
        return this.position = new Hitbox({
            x: sourceEvent['left'], y: sourceEvent['top'], 
            width: sourceEvent['width'], height: sourceEvent['height']
        });
    }

    public getObject(id: number){
        return this.gameObjects.find(gameObject => gameObject.id === Number(id));
    }

    public display(id: number, show: boolean){
        const object = this.getObject(id);
        if (object)
            object['display'] = show;
    }
}