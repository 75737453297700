import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private sourcePage: string = '';

  setSourcePage(source: string) {
    this.sourcePage = source;
  }
  
  getSourcePage(): string {
    return this.sourcePage || 'accueil';
  }
}
