import { Injectable } from '@angular/core';
import { GameService } from './game.service';
import { EventsService } from './event/events.service';
import { InventoryService } from './inventory/inventory.service';
import { Save } from './save.entity';

const TTL = 1000 * 60 * 60;

@Injectable({
  providedIn: 'root'
})
export class SaveService {
  private _lastSave: { debrief: Save | null, game: Save | null } = { debrief : null, game: null };

  constructor(
    private gameService: GameService,
    private eventsService: EventsService,
    private inventoryService: InventoryService
  ) { }

  private get isDebrief(): boolean {
    return window.location.pathname.includes('debriefing');
  }

  private get saveName(){
    return `save-mjn-${this.isDebrief ? 'debrief' : 'game'}`;
  }

  private get lastSave(): Save | null{
    return this._lastSave[this.isDebrief ? 'debrief' : 'game'];
  }

  private set lastSave(save: Save | null){
    this._lastSave[this.isDebrief ? 'debrief' : 'game'] = save;
  }

  save(){
    const createdAt = this.lastSave?.createdAt || new Date().getTime();
    const timer = this.gameService.mainTimer.value;
    const pastEvents = this.eventsService.pastEvents;
    const inventory = this.inventoryService.usesCounter;
    const mainScene = this.gameService.mainScene?.id || 0;
    this.lastSave = new Save(createdAt, timer, pastEvents, inventory, mainScene);
    window.localStorage.setItem(this.saveName, this.lastSave.toString());
  }

  load(){
    const save: Save = this.lastSave || JSON.parse(window.localStorage.getItem(this.saveName) || 'null');
    if (!save) return null;
    if (Date.now() > save.createdAt + TTL)
      return this.clearSave();
    this.eventsService.pastEvents = save.pastEvents;
    this.inventoryService.usesCounter = save.inventory;
    return save;
  }

  exists(name: 'game' | 'debrief'): boolean {
    const save: Save = this.lastSave || JSON.parse(window.localStorage.getItem(`save-mjn-${name}`) || 'null');
    if (save && Date.now() > save.createdAt + TTL){
      this.clearSave();
      return false;
    }
    return  save !== undefined && save !== null;
  }

  clearSave(names: ('debrief' | 'game')[] = ['debrief', 'game']){
    for (const name of names){
      window.localStorage.removeItem(`save-mjn-${name}`);
      this._lastSave[name] = null;
    }
    return null;
  }
}
