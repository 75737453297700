import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  private _start$ = new BehaviorSubject<number>(-1);
  public readonly start$ = this._start$.asObservable();

  private _muted$ = new BehaviorSubject<boolean>(false);
  public readonly muted$ = this._muted$.asObservable();

  private _pause$ = new BehaviorSubject<boolean>(false);
  public readonly pause$ = this._pause$.asObservable();

  constructor() { }

  start(objectId: number){
    this._start$.next(objectId);
  }

  togglePause(){
    this._pause$.next(!this._pause$.getValue());
  }

  toggleMute(){
    this._muted$.next(!this._muted$.getValue());
  }

  play(audio: HTMLAudioElement){
    if (this._muted$.getValue()) return;
    audio.play();
  }
}