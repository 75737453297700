import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GameObject } from './objects/game-object/game-object.entity';
import { DragComponent } from './objects/drag/drag.component';

export interface InputValidator {
  validate: () => boolean;
  gameObject: GameObject;
  htmlElement: ElementRef;
} 

@Injectable({
  providedIn: 'root'
})
export class InputService {

  private _newInput$ = new Subject<string>();
  public newInput$ = this._newInput$.asObservable();
  
  private _inFocus$ = new BehaviorSubject<number[]>([]);
  public inFocus$ = this._inFocus$.asObservable();

  private _validation$ = new Subject<boolean>();
  public validation$ = this._validation$.asObservable();

  private _validators: InputValidator[] = [];
  get validators() { 
    const inFocus = this._inFocus$.getValue();
    return inFocus.length > 0 && !inFocus.includes(-1) ? 
      this._validators.filter(validator => inFocus.includes(validator.gameObject.id)) : 
      this._validators;
  };

  private _grabbedObject$ = new BehaviorSubject<DragComponent | null>(null);
  public readonly grabbedObject$ = this._grabbedObject$.asObservable();

  private _shouldGrab$ = new Subject<number>();
  public readonly shouldGrab$ = this._shouldGrab$.asObservable();

  private _touchOver$ = new Subject<string>();
  public readonly touchOver$ = this._touchOver$.asObservable();

  private previousFocus: number[] = [];

  constructor() { }

  newInput(key: string) {
    this._newInput$.next(key);
  }

  validate(forInputs: any[] = []){
    forInputs = typeof forInputs === 'string' ? [Number(forInputs)] : forInputs.map((id: string) => Number(id));
    let validators = this.validators;
    
    if (forInputs.length > 0)
      validators = validators.filter(validator => forInputs.includes(validator.gameObject.id));
    if (this.validators.length === 0)
      return this._validation$.next(false);

    for (let validator of validators){
      if (!validator.validate())
        return this._validation$.next(false);
    }
    return this._validation$.next(true);
  }

  register(validator: InputValidator){
    this._validators.push(validator);
  }

  unRegister(inputValidator: InputValidator){
    const index = this._validators.findIndex((validator: any) => validator.gameObject.id === (inputValidator as any).gameObject.id);
    if (index !== -1)
      this._validators.splice(index, 1);
  }

  setFocus(ids: number[]){
    this.previousFocus = this._inFocus$.getValue();
    this._inFocus$.next(ids);
  }

  resetFocus(){
    this.setFocus(this.previousFocus || []);
  }

  hasFocus(id: number): boolean {
    const inFocus = this._inFocus$.getValue();
    return inFocus.length === 0 || inFocus.includes(id);
  }

  triggerGrab(objectId: number){
    this._shouldGrab$.next(objectId);
  }

  grabObject(dragComponent: DragComponent){
    dragComponent.setAlternativeAsset();
    this._grabbedObject$.next(dragComponent);
  }

  leaveObject(){
    const grabbedObject = this._grabbedObject$.getValue();
    if (!grabbedObject) return;
    this._grabbedObject$.next(null);
    grabbedObject.setMainAsset();
    grabbedObject.stopDrag();
  }

  hasGrabbedObject(){
    return !!this._grabbedObject$.getValue();
  }

  hasObjectInHand(id: number){
    return this._grabbedObject$.getValue()?.gameObject.id === id;
  }

  touchOver(id: string){
    this._touchOver$.next(id);
  }
}