import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GetSubscriptionsService, SubscriptionResponse, Subscription, AccessCode } from '../services/get-subscriptions.service';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.local';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GameConsole } from '../logger/logger';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  subscriptions: Subscription[] = [];
  accessCodes: AccessCode[] = [];
  private apiUrl = environment.apiUrl;
  roles: string[] | null = null;
  userRole: string = '';

  connectionCountMapping: { [k: string]: string } = {
    '=0': 'aucune connexion',
    '=1': '1 connexion',
    other: '# connexions'
  };

  constructor(
    private titleService: Title,
    private getSubscriptionsService: GetSubscriptionsService,
    private router: Router,
    private globalService: GlobalService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Espace Administrateur');
    this.globalService.setSourcePage('administrateur');
    this.checkUserSession();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private checkUserSession(): void {
    this.http.get<{ roles: string[] }>(`${this.apiUrl}/admin`, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response.roles && response.roles.includes('ROLE_ADMIN')) {
            this.userRole = 'ROLE_ADMIN';
            this.getSubscriptions();
          } else if (response.roles && response.roles.includes('ROLE_CANOPE')) {
            this.userRole = 'ROLE_CANOPE';
            this.getDemoCodes();
          } else {
            this.router.navigate(['/authentification']);
          }
        },
        error: (error) => {
          console.error('Error:', error);
          if (error.status === 403 || error.status === 401) {
            this.router.navigate(['/authentification']);
          }
        }
      });
  }

  private getSubscriptions(): void {
    this.getSubscriptionsService.requestWebService()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: SubscriptionResponse) => {
          this.subscriptions = response.userSubscriptions || [];
        },
        error: (error) => {
          console.error('Error:', error);
          if (error.status === 403) {
            this.router.navigate(['/authentification']);
          }
        },
        complete: () => {
          GameConsole.log('Request completed');
        }
      });
  }

  private getDemoCodes(): void {
    this.http.get<{ accessCodes: AccessCode[] }>(`${this.apiUrl}/admin`, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          this.accessCodes = response.accessCodes || [];
          // Ensure accessCodes is sorted in descending order by id
          this.accessCodes.sort((a, b) => b.id - a.id);
        },
        error: (error) => {
          console.error('Error:', error);
          if (error.status === 403 || error.status === 401) {
            this.router.navigate(['/authentification']);
          }
        },
        complete: () => {
          GameConsole.log('Request completed');
        }
      });
  }

  logout(event: MouseEvent): void {
    event.preventDefault();
    const logoutUrl = `${this.apiUrl}/deconnexion`;

    this.http.get(logoutUrl, { observe: 'response', withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: response => {
          if (response.status === 200) {
            this.router.navigate(['/authentification']);
          }
        },
        error: error => {
          console.error('Logout unsuccessful', error);
          alert('logout unsuccessful');
        }
      });
  }

  confirmNewCode(accessCodeId: number): void {
    const isConfirmed = confirm("Attention : l'ancien code cessera de fonctionner aussitôt. Vous voulez vraiment modifier ce code ?");
    if (isConfirmed) {
      this.getSubscriptionsService.updateAccessCode(accessCodeId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (response) => {
            GameConsole.log('Access code updated successfully', response);
            const newCode: AccessCode = {
              id: response.id,
              accessCode: response.newCode,
              subscriptionStart: response.start,
              subscriptionEnd: response.end,
              connectionCount: response.connection_count
            };

            if (this.userRole === 'ROLE_ADMIN') {
              this.subscriptions.forEach(subscription => {
                const foundCode = subscription.accessCodes.find(code => code.id === accessCodeId);
                if (foundCode) {
                  foundCode.accessCode = newCode.accessCode;
                }
              });
            } else if (this.userRole === 'ROLE_CANOPE') {
              const foundCode = this.accessCodes.find(code => code.id === accessCodeId);
              if (foundCode) {
                foundCode.accessCode = newCode.accessCode;
              }
            }
            
            GameConsole.log(`New code generated: ${newCode.accessCode}`);
          },
          error: (error) => {
            console.error('Error updating access code', error);
          }
        });
    }
  }

  addDemoCode(): void {
    this.http.post(`${this.apiUrl}/add-demo-code`, {}, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: any) => {
          const newCode: AccessCode = {
            id: response.id,
            accessCode: response.access_code,
            subscriptionStart: response.subscription_start,
            subscriptionEnd: response.subscription_end,
            connectionCount: response.connection_count
          };
          this.accessCodes.unshift(newCode);
          // Re-sort the array to ensure descending order by id
          this.accessCodes.sort((a, b) => b.id - a.id);
        },
        error: (error) => {
          console.error('Error adding demo code', error);
        }
      });
  }
}
