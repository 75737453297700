export interface PopupData {
    text: string;
    leftButtonText?: string;
    rightButtonText?: string;
    leftButtonCallback?: Function;
    rightButtonCallback?: Function;
    backgroundImage?: string;
}

export const pausePopup = (callback: Function) => {
    return {
        text: 'Jeu en pause',
        rightButtonText: 'REPRENDRE',
        rightButtonCallback: callback,
    }
}