<!-- src/app/components/authentication/authentication.component.html -->
<div id="page">
    <div id="ferry"><img src="assets/images/home/ferry-cercle-blanc.jpg"></div>
    <div id="content">
      <h1>Cette rubrique est protégée par un mot de passe</h1>
      <h2>Pour en obtenir un, consultez cette page d'aide : <a href="#">créer un compte Réseau Canopé.</a></h2>
      <h2>Si vous avez un compte, cliquez sur le bouton ci-dessous qui vous correspond.</h2>
      <button (click)="onAgentClick()">Agent Canopé</button>
      <button (click)="onUserClick()">Autre</button>
    </div>
    <a routerLink="/accueil"><div id="back-button"><img src="assets/images/home/back-button.png"></div></a>
  </div>
  
  <iframe [src]="agentLogoutUrl" frameborder="0" style="width: 0; height: 0;"></iframe>
  <iframe [src]="userLogoutUrl" frameborder="0" style="width: 0; height: 0;"></iframe>
  