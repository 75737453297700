<span class="flex-center game-font comic">
    <span class="popup flex-center">
        <span class="popup-top-border"></span>
        <span class="popup-text" [innerHTML]="data.text"></span>
        <div class="popup-actions">
            <div *ngIf="data.leftButtonText && data.leftButtonCallback" 
                class="popup-button"
                (click)="onClickButton(data.leftButtonCallback)">
                {{data.leftButtonText}}
            </div>
            <div *ngIf="data.rightButtonText && data.rightButtonCallback" 
                class="popup-button" 
                (click)="onClickButton(data.rightButtonCallback)">
                {{data.rightButtonText}}
            </div>
        </div>
    </span>
</span>
