<div class="game-master-component">
  <app-header title="MAÎTRE DU JEU"></app-header>

  <div class="subtitle-section">

      <div class="block_item">
        <a [href]="clientUrl + '/backend/ressources/dossier-pedagogique.pdf'" target="_blank">
          <div class="item-image"><img src="../../assets/images/maitre-du-jeu/picto_livret.png" alt=""></div>
          <span>Livret pédagogique</span>
        </a>
      </div>

      <div class="block_item">
        <a [href]="clientUrl + '/backend/ressources/solutions.pdf'" target="_blank">
          <div class="item-image"><img src="../../assets/images/maitre-du-jeu/picto_solution.png" alt=""></div>
          <span>Solutions</span>
        </a>
      </div>

      <div class="block_item">
        <a [href]="clientUrl + '/backend/ressources/support-parcours-educatif.pdf'" target="_blank">
          <div class="item-image"><img src="../../assets/images/maitre-du-jeu/picto_debrief.png" alt=""></div>
          <span>Support Parcours Educatifs</span>
        </a>
      </div>

      <div class="block_item">
        <a href="https://valeurs-de-la-republique.reseau-canope.fr/" target="_blank">
          <div class="item-image"><img src="../../assets/images/maitre-du-jeu/picto_bonus.png" alt=""></div>
          <span>En savoir plus sur les Valeurs de la République</span>
        </a>
      </div>

  </div>

</div>
