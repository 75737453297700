import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { ChooseGameComponent } from './pages/choose-game/choose-game.component';
import { ChooseActivityComponent } from './pages/choose-activity/choose-activity.component';
import { CodeControlComponent } from './pages/code-control/code-control.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CguComponent } from './pages/cgu/cgu.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { LegalNoticeComponent } from './pages/legal-notice/legal-notice.component';
import { SessionService } from './services/session.service';
import { inject } from '@angular/core';
import { GameConsole } from './logger/logger';


export const routes: Routes = [
  { path: '', redirectTo: '/accueil', pathMatch: 'full' },
  { path: 'accueil', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {
    path: 'administrateur',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'maitre-du-jeu',
    loadChildren: () => import('./game-master/game-master.module').then(m => m.GameMasterModule),
  },
  {
    path: 'debriefing',
    loadChildren: () => import('./game/game.module').then(m => m.GameModule),
  },
  {
    path: 'jeu',
    loadChildren: () => import('./game/game.module').then(m => m.GameModule),
  },
  { path: 'authentification', component: AuthenticationComponent },
  {
    path: 'joueur',
    component: ChooseGameComponent,
  },
  {
    path: 'activites',
    component: ChooseActivityComponent
  },
  { path: 'faq', component: FaqComponent },
  { path: 'cgu', component: CguComponent },
  { path: 'donnees-personnelles', component: PrivacyComponent },
  { path: 'mentions-legales', component: LegalNoticeComponent },
  { path: 'code', component: CodeControlComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: '/accueil' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
