import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.local';
import { tap } from 'rxjs/operators';
import { GameConsole } from '../logger/logger';

export interface AccessCode {
  id: number;
  accessCode: string;
  subscriptionStart: string;
  subscriptionEnd: string;
  connectionCount?: number;
}

export interface Subscription {
  id: number;
  canopeSubscriptionId: number;
  canopeProductReference: string;
  quantity: number;
  date: string;
  schoolYear: string;
  accessCodes: AccessCode[];
}

export interface SubscriptionResponse {
  message: string;
  username: string;
  userSubscriptions: Subscription[];
}

@Injectable({
  providedIn: 'root'
})
export class GetSubscriptionsService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  requestWebService(): Observable<SubscriptionResponse> {
    const url = `${this.baseUrl}/get-subscriptions`;
    const data = {};
    return this.http.post<SubscriptionResponse>(url, data, { withCredentials: true });
  }

  updateSubscriptions(updatePayload: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/update-subscriptions`, updatePayload, {
        observe: 'response',
        withCredentials: true // Added this line
      })
      .pipe(
        tap((response: HttpResponse<any>) => {
          if (response.status === 200) {
            GameConsole.log('Recipient update successful');
          }
        })
      );
  }

  updateAccessCode(accessCodeId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/update-access-code/${accessCodeId}`, {}, { withCredentials: true });
  }
}
