import { Directive, inject } from '@angular/core';
import { Subscription, concatMap, tap } from 'rxjs';

import { GameService } from '../game/game.service';
import { LoadingService } from '../game/loading/loading.service';
import { Scene } from '../game/scene/scene.entity';

@Directive({
  selector: '[preload]'
})
export class PreloadDirective {
  private gameService = inject(GameService);
  private loadingService = inject(LoadingService);
  private subscription = new Subscription();

  ngOnInit(): void {
    this.subscription = this.gameService.getGame().pipe(
      concatMap((scene: Scene) => this.gameService.preloadScene(scene.id)),
      concatMap((scene) => this.loadingService.preloadAssets({ urls: scene.files, sceneId: scene.id })),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
