import { GameEvent, GameEventTriggers } from "../../event/game-event.entity";



export class GameObject {
    public readonly id: number;
    public readonly name: string;
    public readonly hitbox: Hitbox;
    public onClickEvents!: GameEvent[];
    public onInitEvents!: GameEvent[];
    public onExitEvents!: GameEvent[];
    public onIdleEvents!: GameEvent[];
    public onParentEvents!: GameEvent[];
    public onValidatedEvents!: GameEvent[];
    public onNotValidatedEvents!: GameEvent[];
    public onMouseEnterEvents!: GameEvent[];
    public onMouseLeaveEvents!: GameEvent[];
    public onDropEvents!: GameEvent[];
    public onCheckedEvents!: GameEvent[];
    public readonly sceneId: number;
    public readonly image?: string;
    public readonly type?: GameObjectTypes;
    public readonly files?: string[];
    [key: string]: any;
    constructor(
        data : {
            id: number,
            name: string,
            hitboxTop: number,
            hitboxLeft: number,
            hitboxHeight: number,
            hitboxWidth: number,
            events: any[],
            sceneId: number,
            lockedUntil?: GameEvent,
            inventoryObject?: string,
            image?: string,
            objectType: string,
            attributes: any[]
        }
    ){
        this.id = data.id
        this.name = data.name;
        this.hitbox = new Hitbox({x: data.hitboxLeft, y: data.hitboxTop, width: data.hitboxWidth,  height: data.hitboxHeight});
        data.events.sort((a, b) => a.positionInSequence - b.positionInSequence);
        this.sortEvents(data.events);
        this.image = data.image;
        this.sceneId = data.sceneId;
        this.type = data.objectType as GameObjectTypes; 
        this.setKeyEvents();
        this.getAttributes(data.attributes || []);
        this.files = this.gatherFiles();
    }

    private sortEvents(events: any[]){
        this.onClickEvents = this.getEventsWithTrigger('click', events);
        this.onInitEvents = this.getEventsWithTrigger('enterScene', events);
        this.onExitEvents = this.getEventsWithTrigger('exitScene', events);
        this.onIdleEvents = this.getEventsWithTrigger('idle', events);
        this.onParentEvents = this.getEventsWithTrigger('parentEvent', events);
        this.onValidatedEvents = this.getEventsWithTrigger('inputValidated', events);
        this.onNotValidatedEvents = this.getEventsWithTrigger('inputNotValidated', events);
        this.onMouseEnterEvents = this.getEventsWithTrigger('mouseEnter', events);
        this.onMouseLeaveEvents = this.getEventsWithTrigger('mouseLeave', events);
        this.onDropEvents = this.getEventsWithTrigger('drop', events);
        this.onCheckedEvents = this.getEventsWithTrigger('checked', events);
    }

    private getEventsWithTrigger(trigger: GameEventTriggers, events: any[]): GameEvent[] {
        const filteredEvents = events.filter((event: any) => event.eventTrigger === trigger)
                     .map((event: any) => new GameEvent(event));
        return this.normalizePositions(filteredEvents);
    }

    private setKeyEvents(){
        const keyEvents = this.onClickEvents.filter((event: any) => event.type === 'key');
        if (this.type === 'key' && keyEvents.length === 0){
            const event = new GameEvent({ id:-1, eventType: 'key', eventTrigger: 'click', positionInSequence: 0, attributeValues: [] });
            keyEvents.push(event);
            this.onClickEvents.push(event);
        }
        for (const keyEvent of keyEvents){
            if (keyEvent['key']) continue;
            keyEvent['key'] = this.name;
        }
    }

    private normalizePositions(events: GameEvent[]): GameEvent[] {
        if (events.length === 0) return events;
        events.sort((a, b) => a.positionInSequence - b.positionInSequence);
        const positions = new Set(events.map((event) => event.positionInSequence));
        const minPos = Math.min(...positions);
        const offset = 0 - minPos;
        for (const event of events)
            event.positionInSequence += offset;
        return events;
    }

    private getAttributes(attributes: any){
        for (let attribute of attributes){
            if (this.shouldMakeAlist(attribute))
                this[attribute.attributeName] = attribute.value.split(',');
            else if (attribute.value === 'true')
                this[attribute.attributeName] = true;
            else if (attribute.value === 'false')
                this[attribute.attributeName] = false;
            else
                this[attribute.attributeName] = attribute.value;
        }
    }

    private gatherFiles(): string[]{
        const idleFrames = this.getFramesFromEvent('idle');
        const parentEventFrames = this.getFramesFromEvent('parentEvent');
        const images = this.getUrlsFromEvents();
        const urls: string[] = [...idleFrames, ...parentEventFrames, ...images];
        if (this.image)
            urls.push(this.image);
        if (this['fileUrl'])
            urls.push(this['fileUrl']);
        return urls;
    }

    private getUrlsFromEvents(): string[]{
        return this.events.filter((event) =>!!event['fileUrl']).map((event) => event['fileUrl']);
      }

    private getFramesFromEvent(trigger: 'idle' | 'parentEvent'){
        const objectEvents = trigger === 'parentEvent' ? this.onParentEvents : this.onIdleEvents;
        return objectEvents
          .filter((event) => !!event && !!event['animationFrames'])
          .map((event) => event['animationFrames'])
          .reduce((accumulator, value) => accumulator.concat(value), []);
    }

    get events() {
        return [
            ...this.onClickEvents, 
            ...this.onInitEvents, 
            ...this.onExitEvents,
            ...this.onParentEvents,
            ...this.onValidatedEvents,
            ...this.onNotValidatedEvents,
            ...this.onMouseEnterEvents,
            ...this.onMouseLeaveEvents,
            ...this.onIdleEvents,
            ...this.onDropEvents,
            ...this.onCheckedEvents,
        ].filter((event) => !!event);
    }

    private shouldMakeAlist(attribute: any): boolean {
        return (typeof attribute.value === 'string' && attribute.value.includes(',') && attribute.attributeName !== 'text') || ['lockedUntil', 'lockedAfter'].includes(attribute.attributeName);
    }
}

export class Hitbox {
    public x: string;
    public y: string;
    public width: string;
    public height: string;
    constructor(
        data: {
            x: number,
            y: number,
            width: number,
            height: number
        }
    ){
        this.x = `${data.x}%`;
        this.y = `${data.y}%`;
        this.width = `${data.width}%`;
        this.height = `${data.height}%`;
    }
}
export type GameObjectTypes =
    'audio' |
    'button' |
    'key' |
    'drag' |
    'drop' |
    'text' |
    'inputField' |
    'hint' |
    'object' |
    'video' |
    'inventoryItem' |
    'checkbox'|
    'checkboxGroup';