import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { GameMasterComponent } from './game-master/game-master.component';
import { CodeControlComponent } from './pages/code-control/code-control.component';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { ChooseGameComponent } from './pages/choose-game/choose-game.component';
import { ChooseActivityComponent } from './pages/choose-activity/choose-activity.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoaderComponent } from './loading/loader/loader.component';
import { FaqComponent } from './pages/faq/faq.component';
import { PreloadDirective } from './loading/preload.directive';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    GameMasterComponent,
    CodeControlComponent,
    AuthenticationComponent,
    UploadImageComponent,
    HeaderComponent,
    ChooseGameComponent,
    ChooseActivityComponent,
    ContactComponent,
    LoaderComponent,
    FaqComponent,
    PreloadDirective
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
