<div class="game-master-component">
  <app-header title="MAÎTRE DU JEU"></app-header>

  <div class="subtitle-section">
    <span>Pour animer le jeu :</span>
    <img src="assets/images/admin/line-separator.png">
    <div class="block_items">
      <div class="block_item">
        <a href="">
          <img src="../../assets/images/maitre-du-jeu/picto_livret.png" alt="">
          <span>Livret pédagogique</span>
        </a>
      </div>
      <div class="block_item">
        <a href="">
          <img src="../../assets/images/maitre-du-jeu/picto_solution.png" alt="">
          <span>Solutions</span>
        </a>
      </div>
    </div>
  </div>

  <div class="subtitle-section">
    <span>Le débrief :</span>
    <img src="assets/images/admin/line-separator.png">
    <div class="block_items">
      <div class="block_item">
        <a routerLink="/debriefing">
          <img src="../../assets/images/maitre-du-jeu/picto_debrief.png" alt="">
          <span>Débrief complet</span>
        </a>
      </div>
      <div class="block_item">
        <a href="">
          <img src="../../assets/images/maitre-du-jeu/picto_video.png" alt="">
          <span>Capsules vidéos</span>
        </a>
      </div>
      <div class="block_item">
        <a href="">
          <img src="../../assets/images/maitre-du-jeu/picto_debrief.png" alt="">
          <span>Support débriefing</span>
        </a>
      </div>
      <div class="block_item">
        <a href="">
          <img src="../../assets/images/maitre-du-jeu/picto_bonus.png" alt="">
          <span>Bonus</span>
        </a>
      </div>
    </div>
  </div>

  <div class="subtitle-section">
    <span>Pour en savoir plus sur les Valeurs de la République</span>
  </div>

</div>