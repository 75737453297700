import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, take, tap } from 'rxjs';
import { GameEvent } from './game-event.entity';
import { GameService } from '../game.service';
import { InputService } from '../input.service';
import { GameObject } from '../objects/game-object/game-object.entity';
import { InventoryService } from '../inventory/inventory.service';
import { AudioService } from '../objects/audio/audio.service';
import { GameConsole } from 'src/app/logger/logger';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private _triggerNextEvent$ = new Subject<number>();
  public readonly triggerNextEvent$ = this._triggerNextEvent$.asObservable();

  private _activeEvents$ = new BehaviorSubject<GameEvent[]>([]);
  public readonly activeEvents$ = this._activeEvents$.asObservable();
  
  private _currentEvent$ = new BehaviorSubject<GameEvent | null>(null);
  public readonly currentEvent$ = this._currentEvent$.asObservable();

  private _revealObjectsPositions$ = new Subject<string>();
  public readonly revealObjectsPositions$ = this._revealObjectsPositions$.asObservable();

  private _pastEvents: number[] = [];
  get pastEvents() { return this._pastEvents };
  set pastEvents(events: number[]) { this._pastEvents = events };

  private gameObjectId: number | null = null;
  private eventsSequence: GameEvent[] = [];
  private currentSequenceIndex: number = 0;
  private timerSubscription = new Subscription();

  constructor(
    private gameService: GameService,
    private inputService: InputService,
    private inventoryService: InventoryService,
    private audioService: AudioService
  ) { }

  async newGameEvents(events: GameEvent[], gameObjectId: number | null){
    this.gameObjectId = gameObjectId;
    if (events.length === 0 || this.sameEvents(events))
      return this.closeActiveEvents();
    const eventsToInstantiate = this.getEventsToInstantiate(events);
    for (let event of events){
    GameConsole.log('triggered event : ',event, this.gameObjectId);
      this.setEventScene(event);
      if (event['delay'])
        await this.delay(event['delay'])
      if (this._currentEvent$.getValue() === event && !event.canBeTriggeredTwice)
        return this.closeActiveEvents();
      if (event.trigger === 'mouseEnter' && event['withObject'] && !this.inputService.hasObjectInHand(Number(event['withObject'])))
        return;
      if (event && !this._pastEvents.includes(event.id))
        this.rememberEvent(event.id);
      this._currentEvent$.next(event);
      this._activeEvents$.next(eventsToInstantiate);
      this.handleNewEvent(event);
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, Number(ms)));
  }

  private sameEvents(triggeredEvents: GameEvent[]): boolean {
    const activeEvents = this._activeEvents$.getValue();
    if (triggeredEvents.length !== activeEvents.length)
        return false;
    return activeEvents.every((val, index) => val === triggeredEvents[index]);
  }

  private getEventsToInstantiate(events: GameEvent []): GameEvent[] {
    return events.filter((event) => ['text', 'image', 'audio', 'reward'].includes(event.type)) 
  }

  private handleNewEvent(event: GameEvent | null){
      switch (event?.type) {
        case 'closeScene':
          return this.onCloseSceneEvent(event);
        case 'grab':
          return this.inputService.triggerGrab(Number(event['objectId']));
        case 'inputValidation':
          return this.inputService.validate(event['forInputs']);
        case 'inventory':
          return this.inventoryService.toggle();
        case 'leaveObject':
          return this.inputService.leaveObject();
        case 'key':
          return this.inputService.newInput(event['key']);
        case 'nextScene':
          return this.onNextScene();
        case 'openScene':
          return this.onOpenSceneEvent(event);
        case 'revealObjectsPositions':
          return this._revealObjectsPositions$.next(event['fileUrl']);
        case 'restartGame':
          return this.onRestartGame();
        case 'startTimer':
          return this.timerSubscription = this.gameService.startTimer().subscribe();
        case 'stopTimer':
          return this.gameService.mainTimer.stop();
        case 'startAudio':
          return this.audioService.start( Number(event['audioId']));
        case 'updateObjectUses':
          return this.inventoryService.updateUsesCounter(event['objectId'], Number(event['numberOfUses']));
        default:
          return;
      }
  }

  private rememberEvent(id: number){
    if (this._pastEvents.includes(id)) return;
    this._pastEvents.push(id);
  }

  areLocked(events: GameEvent []): boolean {
    for (let event of events){
      if (event['lockedUntil'] && !this.eventsAlreadyTriggered(event['lockedUntil']))
        return true;
    }
    return false;
  }

  haveBeenLocked(events: GameEvent[] | GameObject[]): boolean {
    for (let event of events){
      if (event['lockedAfter'] && this.eventsAlreadyTriggered(event['lockedAfter']))
        return true;
    }
    return false;
  }

  onOpenSceneEvent(event: GameEvent){
    this.gameService.getScene(Number(event['sceneId']), event).pipe(
      tap(() => this.closeActiveEvents()),
      tap(() => this.inventoryService.close(true)),
    ).subscribe().unsubscribe();
  }

  onCloseSceneEvent(event: GameEvent){
    this.gameService.closeScene(Number(event['sceneId']));
    this.closeActiveEvents();
  }

  onNextScene(){
    this.inventoryService.close(true);
    return this.gameService.triggerNextScene().pipe(take(1)).subscribe();
  }

  onRestartGame(){
    this.closeActiveEvents();
    this._pastEvents = [];
    this.timerSubscription.unsubscribe();
    this.inventoryService.resetUsesCounter();
    this.inventoryService.close();
    this.gameService.restart();
  }

  eventsAlreadyTriggered(ids: string[]): boolean {
    for (let id of ids){
      if (!this._pastEvents.includes(Number(id)))
        return false;
    }
    return true;
  }

  closeEvent(id: number){
    if (this._currentEvent$.getValue()?.id === id)
      this._currentEvent$.next(null);
    let activeEvents = this._activeEvents$.getValue();
    const index = activeEvents.findIndex((event) => event.id === id);
    if (index === -1) return;
    activeEvents.splice(index, 1);
    this._activeEvents$.next(activeEvents);
  }

  closeActiveEvents(){
    this._currentEvent$.next(null);
    this._activeEvents$.next([]);
  }

  triggerNextEvent(){
    if (this.eventsSequence.length > 0 && this.eventsSequence[this.currentSequenceIndex])
      return this.iterateEventsSequence();
    else if (this.gameObjectId)
      this._triggerNextEvent$.next(this.gameObjectId);
    else
      this.closeActiveEvents();
  }

  private iterateEventsSequence(){
    const events = this.eventsSequence.filter((event) => event.positionInSequence === this.currentSequenceIndex);
    if (!this.shouldContinueSequence(events)) return;
    this.newGameEvents(events, null);
    this.currentSequenceIndex++;
    if (this.shouldTriggerNextEvent())
        this.triggerNextEvent();
    if (this.currentSequenceIndex >= this.eventsSequence.length)
      this.clearSequence();
  }

  private shouldContinueSequence(events: GameEvent[]): boolean {
    if (this.areLocked(events)){
      this.closeActiveEvents();
      return false;
    }
    if (this.haveBeenLocked(events)){
      this.updateEventIndex(this.eventsSequence);
      this.eventsSequence.length > 1 ? this.triggerNextEvent() : undefined;
      return false;
    }
    return true;
  }

  private shouldTriggerNextEvent(){
    return this.eventsSequence[this.currentSequenceIndex - 1]?.type === 'closeScene' ||
           ((this.currentSequenceIndex < this.eventsSequence.length && 
              !this.areLocked(this.eventsSequence.slice(this.currentSequenceIndex))) && 
              this._activeEvents$.getValue()?.length === 0);
  }

  private updateEventIndex(events: GameEvent[]){
    const nextEvents = events.filter((event: GameEvent) => event.positionInSequence === this.currentSequenceIndex + 1);
    if (nextEvents.length === 0)
      this.currentSequenceIndex = this.shouldCloseEvent(events) ? -1 : 0;
    else
      this.currentSequenceIndex++;
  }

  private shouldCloseEvent(events: GameEvent[]): boolean {
    if (events.length === 0)
      return true;
    const lastEvent = events[events.length -1];
    return ['text', 'image', 'audio'].includes(lastEvent.type);
  }

  private clearSequence(){
    this.eventsSequence = [];
    this.currentSequenceIndex = 0;
  }

  setEventsSequence(events: GameEvent[]){
    if (events.length === 0) return;
    this.eventsSequence = events;
    this.currentSequenceIndex = 0;
    this.triggerNextEvent();
  }

  private setEventScene(event: GameEvent | null){
    if (!event) return;
    setTimeout(() => { // necessary when the top scene is beeing deleted
      event.setScene(this.gameService.getTopSceneId());
    });
  }
}