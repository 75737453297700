import { Component, AfterViewInit, Renderer2, ElementRef } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements AfterViewInit {

  private buttonReplaced = false; // Flag to indicate if the button has been replaced

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngAfterViewInit() {
    this.addExternalScript('https://canope.easiwebforms.net/static/shell.js')
      .then(() => {
        this.waitForESW();
      })
      .catch((error: any) => {
        console.error('Error loading the script:', error);
      });
  }

  addExternalScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.charset = 'utf-8';
      script.onload = () => resolve();
      script.onerror = (error: Event) => reject(error);
      this.renderer.appendChild(document.body, script);
    });
  }

  waitForESW() {
    const checkESW = () => {
      if (typeof (window as any).esw !== 'undefined') {
        this.initializeForm();
      } else {
        setTimeout(checkESW, 100); // Check again after 100 milliseconds
      }
    };
    checkESW();
  }

  initializeForm() {
    try {
      (window as any).esw.forms.create({
        "solutionId": "1f48d8603a3802e76b8e417d574b972fef78b07f",
        "formId": "8",
        "language": "fr",
        "buildinelement": "contact-form-container"
      });
      // Apply custom styles programmatically
      this.injectCustomStyles();
      // Observe changes to replace validate button
      this.observeFormChanges();
    } catch (error: any) {
      console.error('Error initializing the form:', error);
    }
  }

  injectCustomStyles() {
    const css = `
      #contact-form-container {
        color: white;
        height: 60vh;
        overflow: auto;
      }
  
      #contact-form-container span {
        display: block;
      }
  
      #contact-form-container .mandatory {
        display: none;
      }
  
      #contact-form-container div {
        margin-bottom: 30px;
      }
  
      #contact-form-container input, textarea {
        width: 500px;
        padding: 10px;
      }
  
      #contact-form-container #mandatoryfieldmention {
        display: none;
      }
  
      #contact-form-container a {
        color: #E66826;
        text-decoration: none;
      }
  
      #easi_labelspan_Description::after {
        content: "En cas de problème technique, merci de préciser si vous utilisez un ordinateur (en indiquant votre navigateur web) ou une tablette (en précisant s'il s'agit d'un appareil Apple ou Android).";
        display: block;
        margin: 20px auto;
        color: #E66826;
        font-size: 14px;
        font-family: arial;
      }
  
      #validatebutton {
        display: none;
      }
  
      #easiformmessages {
        position: fixed;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        color: #E66826;
        text-align: center;
      }

      #additionnalInformation {
        font-size: 14px;
        font-family: arial;
      }

    `;
    const style = this.renderer.createElement('style');
    this.renderer.setProperty(style, 'type', 'text/css');
    this.renderer.appendChild(style, this.renderer.createText(css));
    this.renderer.appendChild(document.head, style);
  }
  

  observeFormChanges() {
    const formContainer = this.el.nativeElement.querySelector('#contact-form-container');

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const validateButton = formContainer.querySelector('#validatebutton');
          if (validateButton && !this.buttonReplaced) {
            this.hideAndTriggerButton(validateButton);
            this.buttonReplaced = true; // Set flag to true after replacement
            observer.disconnect(); // Stop observing once the button is replaced
          }
        }
      });
    });

    observer.observe(formContainer, { childList: true, subtree: true });
  }

  hideAndTriggerButton(validateButton: HTMLElement) {
    // Hide the existing button
    this.renderer.setStyle(validateButton, 'display', 'none');

    // Create an image to replace the button
    const img = this.renderer.createElement('img');
    this.renderer.setAttribute(img, 'src', 'assets/images/home/validate-button.png');
    this.renderer.setAttribute(img, 'alt', 'Validate');
    this.renderer.setStyle(img, 'cursor', 'pointer');
    this.renderer.setStyle(img, 'position', 'fixed');
    this.renderer.setStyle(img, 'bottom', '20px');
    this.renderer.setStyle(img, 'right', '15px');
    this.renderer.setStyle(img, 'width', '200px');

    // Add click event to the image to trigger the hidden button
    this.renderer.listen(img, 'click', () => {
      validateButton.click();
    });

    // Append the image to the body
    this.renderer.appendChild(document.body, img);
  }
}