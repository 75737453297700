import { Component, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GameConsole } from '../logger/logger';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnDestroy {
  selectedFile: File | null = null;
  private subscription: Subscription = new Subscription();

  constructor(private http: HttpClient) { }

  onFileSelected(event: Event): void {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.selectedFile = fileList[0];
    }
  }

  uploadImage(event: Event): void {
    event.preventDefault();
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile, this.selectedFile.name);

      const uploadUrl = 'http://localhost:8000/media/upload/';

      this.subscription.add(
        this.http.post(uploadUrl, formData).subscribe({
          next: (response) => {
            GameConsole.log(response);
            alert('File uploaded successfully');
            this.resetForm();
          },
          error: (error) => {
            console.error(error);
            alert('Failed to upload the file');
          },
          complete: () => GameConsole.log('Complete')
        })
      );
    }
  }

  private resetForm(): void {
    this.selectedFile = null;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
