import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, map, of, switchMap, tap } from 'rxjs';

import { environment } from 'src/environments/environment.local';
import { GameObject } from '../objects/game-object/game-object.entity';
import { LoadingService } from '../loading/loading.service';
import { DragComponent } from '../objects/drag/drag.component';
import { GameObjectComponent } from '../objects/game-object/game-object.component';
import { Scene } from '../scene/scene.entity';
import { GameService } from '../game.service';
import { InputService } from '../input.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  private _visible$ = new BehaviorSubject<boolean>(false);
  public readonly visible$ = this._visible$.asObservable();

  private _grabbedObject$ = new BehaviorSubject<DragComponent | null>(null);
  public readonly grabbedObject$ = this._grabbedObject$.asObservable();

  inventoryCache!: GameObject[];
  private _usesCounter:{ [key: string]: number } = {};
  get usesCounter() { return this._usesCounter };
  set usesCounter(counter: { [key: string]: number }) { this._usesCounter = counter };

  private inventoryScene!: Scene;

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private gameService: GameService,
    private inputService: InputService
  ) { }

  toggle(){
    if (this.inputService.hasGrabbedObject()) return;
    this._visible$.next(!this._visible$.getValue());
  }

  close(leaveObject = false){
    if (!leaveObject && this.inputService.hasGrabbedObject()) return;
    if (leaveObject && this.inputService.hasGrabbedObject())
      this.inputService.leaveObject();
    this._visible$.next(false);
  }

  getInventoryContent(){
    let inventory$;
    if (this.inventoryCache)
      inventory$ = of(this.inventoryCache);
    else
      inventory$ = this.http.get<any[]>(`${environment.apiUrl}/load-inventory/${environment.gameId}`, { withCredentials: true }).pipe(
        tap((data: any) => this.setInventoryScene(data)),
        map((data: any) => data.gameObjects.map((gameObject: any) => new GameObject(gameObject))),
        tap((gameObjects) => this.inventoryCache = gameObjects),
        concatMap(() => this.gameService.preLoadSubScenes(this.inventoryScene)),
        switchMap(() => this.loadingService.preloadAssets({ urls: this.getInventoryFiles(), sceneId: -1})),
        map(() => this.inventoryCache),
      );
    return inventory$;
  }

  private setInventoryScene(data: any){
    this.inventoryScene = new Scene({scene: {id: -1, name : 'inventory', backgroundImage: '' },  gameObjects: data.gameObjects})
  }

  private getInventoryFiles(){
    const files = [];
    for (let gameObjectFiles of this.inventoryCache.map((gameObject) => gameObject.files)){
      if (gameObjectFiles && gameObjectFiles.length > 0)
          files.push(...gameObjectFiles);
    }
    return files;
  }

  canUse(component: GameObjectComponent): boolean {
    const allowedUses = component.gameObject['numberOfUses'];
    if (!allowedUses) return true;
    const uses = this._usesCounter[component.gameObject.id.toString()] || 0;
    return uses < Number(allowedUses);
  }

  updateUsesCounter(objectId: string, amount: number){
    const uses = this._usesCounter[objectId] || 0;
    if (uses + amount < 0) return;
    this._usesCounter[objectId] = uses + amount;
  }
  
  resetUsesCounter(){
    this. _usesCounter = {};
  }
}