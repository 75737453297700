<div id="page">
  <div id="ferry"><img src="assets/images/home/ferry-cercle-blanc.jpg"></div>
  <div id="content">
    <h1>Bienvenue dans l'escape game Mission Jaurès</h1>
    <h2>Vous aurez 40 minutes pour réussir ce jeu. Avant cela, nous allons effectuer quelques réglages.</h2>
    <h2>Pour continuer, vous devez d'abord saisir le code qui vous a été fourni.</h2>
    <h2><a href="https://www.reseau-canope.fr/service/mission-jaures-un-escape-game-pour-aborder-la-laicite-autrement">Si vous n'avez pas de code, vous pouvez en obtenir un ici : Réseau Canopé</a></h2>
    <input id="access-code" 
           #accessCodeInput
           type="text" 
           [(ngModel)]="accessCode" 
           (keyup.enter)="codeControl()" 
           (input)="clearErrorMessage()"
           placeholder="Code d'accès">
    <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
  <a routerLink="/accueil"><div id="back-button"><img src="assets/images/home/back-button.png"></div></a>
  <div id="enter-button" (click)="codeControl()"><img src="assets/images/home/validate-button.png"></div>
</div>
