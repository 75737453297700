import { BehaviorSubject, EMPTY, Observable, Subscription, timer } from 'rxjs';

export class Timer {
  private timeElapsed = 0;
  private _timer!: Observable<number>;
  private subscription = new Subscription();
  private started = false;
  private readonly step: number;
  
  private update = new BehaviorSubject<number>(0);
  public readonly timer$ = this.update.asObservable();

  get value(){ return this.update.getValue() };

  constructor(step: number) {
    this.timeElapsed = 0;
    this.step = step;
  }

  start(from?: number) {
    if (this.started) return;
    if (from)
      this.timeElapsed = from;
    this.started = true;
    this._timer = timer(0, this.step);
    this.subscription = this._timer.subscribe(() => {
      this.timeElapsed = this.timeElapsed + 1;
      this.update.next(this.timeElapsed);
    });
  }

  pause() {
    if(! this.started) return;
    this.started = false;
    this.subscription.unsubscribe();
    this._timer = EMPTY;
  }

  stop() {
    if(! this.started) return;
    this.subscription.unsubscribe();
    this._timer = EMPTY;
  }
}
