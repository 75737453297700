import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment.local';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) {}

  checkSession(page: string): Observable<boolean> {
    return this.http.get(`${this.apiUrl}/check-session?page=${page}`, { observe: 'response', withCredentials: true }).pipe(
      map((response: HttpResponse<any>) => {
        if (response.status === 200 && response.body.message === 'Cookie, session, and user roles are valid') {
          const user = {
            username: response.body.username
          };
          this.userService.setUser(user);
          return true;
        }
        return false;
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }

  updateSession(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/update-session`, {}, { withCredentials: true });
  }

  signOut(): void {
    window.location.href = `${this.apiUrl}/sign-out`;
  }
}
