import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { SessionService } from '../services/session.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title: string = 'Maître du jeu';
  username: string | null = null;
  roles: string[] | null = null;
  public sourcePage: string | null = null;

  constructor(
    private globalService: GlobalService,
    private sessionService: SessionService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.sourcePage = this.globalService.getSourcePage();
    this.updateTitle();
  
    // Subscribe to the user service to get the current user
    this.userService.getUser().subscribe(user => {
      if (user) {
        this.username = user.username;
      } else {
        this.username = null;
      }
    });
  } 

  updateTitle() {
    if (this.sourcePage === 'administrateur') {
      this.title = 'Administrateur';
    } else if (this.sourcePage === 'maitre-du-jeu') {
      this.title = 'Maître du jeu';
    }
  }

  signOut() {
    this.sessionService.signOut();
  }
}
