<div id="page">
    <div id="content">
        <h1>Conditions Générales d'Utilisation (CGU)</h1>
        <h2>du site "Mission Jaurès"</h2>
    </div>

    <div id="scrolling-list">
        <p class="title">1. Objet</p>
        <p>
            Les présentes Conditions Générales d'Utilisation (CGU) régissent l'accès et l'utilisation du site Mission Jaurès, propriété du Réseau Canopé, dont le siège social est situé à 
            <br><br>
            Téléport 1 – Bât. @4, 1 avenue du Futuroscope, <br>
            CS 80158, 86961 Futuroscope Cedex, France.
        </p>
        <p>En accédant à ce site, vous acceptez sans réserve les présentes CGU.</p>

        <p class="title">2. Services proposés</p>
        <p>
            Le site Mission Jaurès propose un jeu en ligne éducatif ayant pour but d'aborder la thématique de la laïcité. Les utilisateurs peuvent accéder à du contenu pédagogique et à des parcours éducatifs, incluant des vidéos, des quiz, et des documents de formation sur l'histoire et les principes de la laïcité.
        </p>

        <p class="title">3. Accès au site et au jeu</p>
        <p>
            L’accès au site Mission Jaurès et à ses fonctionnalités est réservé aux utilisateurs disposant d’un compte Réseau Canopé et ayant souscrit un abonnement.
        </p>

        <p class="subtitle">Souscription de l’abonnement</p>
        <p>
            L’abonnement au jeu Mission Jaurès doit être acheté via le site Réseau Canopé. Seuls les utilisateurs possédant un compte Réseau Canopé peuvent souscrire à l'abonnement et accéder aux fonctionnalités administratives du jeu.
        </p>

        <p class="subtitle">Création et gestion des codes de jeu</p>
        <p>
            Une fois l'abonnement souscrit, l’utilisateur disposant des droits administratifs doit se connecter à son compte Réseau Canopé et cliquer sur le bouton « Administrateur ». Il pourra alors générer un code de jeu, lequel sera transmis soit directement aux joueurs, soit au Maître du jeu. Ce code est indispensable pour accéder au jeu et aux parcours éducatifs.
        </p>

        <p class="subtitle">Accès pour les joueurs et le maître du jeu</p>
        <ul>
            <li>Les joueurs accèdent au jeu en renseignant le code qui leur a été fourni.</li>
            <li>Le Maître du jeu, en plus de l'accès au jeu, bénéficie d’un accès exclusif aux supports pédagogiques d'encadrement en cliquant sur le bouton « Maître du jeu » et en se connectant à son compte Réseau Canopé.</li>
        </ul>

        <p class="subtitle">Problèmes d'accès</p>
        <p>
            En cas de difficulté d’accès, il est recommandé d’essayer différents navigateurs ou de changer d’appareil. Si le problème persiste, les utilisateurs peuvent contacter le support via le formulaire de contact disponible sur le site.
        </p>

        <p class="title">4. Propriété intellectuelle</p>
        <p class="subtitle">Jeu</p>
        <p>
            Réseau Canopé est propriétaire du jeu Mission Jaurès, tant dans ses composantes techniques que graphiques, textuelles ou autres, sous la seule réserve des données ou contenus fournis par des tiers. Le jeu est fourni au moyen de logiciels et de bases de données conçus et développés par Réseau Canopé, qui lui appartiennent ou sur lesquels il dispose des droits de propriété intellectuelle, sous réserve des droits des tiers.
        </p>

        <p>
            Réseau Canopé est donc seul titulaire de l'ensemble des droits de propriété intellectuelle afférents au jeu, ainsi qu'aux logiciels et bases de données assurant son fonctionnement. L'utilisation du jeu ne confère à l’utilisateur aucun droit de propriété sur l'un quelconque de ces éléments. Les utilisateurs du jeu reconnaissent les droits de propriété intellectuelle de Réseau Canopé et s’engagent à les respecter.
        </p>

        <p class="subtitle">Livret pédagogique</p>
        <p>
            Réseau Canopé est titulaire des droits de propriété intellectuelle afférents au livret pédagogique et concède, à titre gratuit, aux utilisateurs une licence non exclusive d'utilisation du livret. Les utilisateurs ne sont pas autorisés à reproduire ou utiliser les contenus (textes, images, etc.) du livret à d’autres fins que celles prévues dans le cadre de l’utilisation du jeu et du livret.
        </p>

        <p class="title">5. Responsabilité de l'utilisateur</p>
        <p>
            L’utilisateur s’engage à fournir des informations exactes lors de la création de son compte et à ne pas partager ses identifiants de connexion. Il est responsable de l’utilisation de ses identifiants. Toute utilisation abusive des services, incluant la diffusion de contenus illégaux, est strictement interdite.
        </p>

        <p class="title">6. Responsabilité de l'éditeur</p>
        <p>
            L'éditeur du site, Réseau Canopé, s’efforce de garantir la disponibilité des services proposés, mais ne saurait être tenu responsable en cas d'interruptions ou de dysfonctionnements techniques hors de son contrôle.
        </p>

        <hr>

        <p class="title">MENTIONS LEGALES</p>
        <p class="subtitle">Présentation générale</p>
        <p>
            Dénomination : Réseau Canopé <br>
            Siège social : Téléport 1 @4, 1 avenue du Futuroscope,<br>CS 80158, 86961 Futuroscope CEDEX <br>
            Tél. (standard) : 05 49 49 78 00 <br>
            Télécopie : 05 49 49 78 06
        </p>

        <p class="subtitle">Directrice de publication</p>
        <p>
            Marie-Caroline Missir, Directrice générale
        </p>
    </div>

    <a routerLink="/accueil">
        <div id="back-button">
            <img src="assets/images/home/back-button.png" alt="Back">
        </div>
    </a>
</div>
