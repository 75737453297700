import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.local';
import { UserService } from './user.service';
import { GlobalService } from './global.service';  // Import GlobalService

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private globalService: GlobalService  // Inject GlobalService
  ) {}

  updateSession(): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/update-session`, {}, { withCredentials: true });
  }

  signOut(): void {
    // Set source page to 'accueil' before signing out
    this.globalService.setSourcePage('accueil');

    // Send a POST request to the sign-out endpoint to invalidate the session
    this.http.post(`${this.apiUrl}/sign-out`, {}, { withCredentials: true }).subscribe({
      next: () => {
        // Clear the user data from UserService
        this.userService.clearUser();

        // Redirect the user to the home or login page after sign out
        window.location.href = environment.clientUrl + '/authentification';
      },
      error: (err) => {
        console.error('Error during sign-out:', err);
        // Handle sign-out failure (if any), but still clear user and redirect
        this.userService.clearUser();
        window.location.href = environment.clientUrl + '/authentification';
      }
    });
  }
}
