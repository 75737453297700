import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.local';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-code-control',
  templateUrl: './code-control.component.html',
  styleUrls: ['./code-control.component.scss']
})
export class CodeControlComponent implements AfterViewInit, OnDestroy {
  accessCode: string = '';
  errorMessage: string = '';
  private baseUrl = environment.apiUrl;
  private unsubscribe$ = new Subject<void>();

  @ViewChild('accessCodeInput') accessCodeInput!: ElementRef;

  constructor(private http: HttpClient, private router: Router) {}

  ngAfterViewInit(): void {
    this.accessCodeInput.nativeElement.focus();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  codeControl(): void {
    if (this.accessCode.trim() === '') {
      this.errorMessage = 'Le code est vide';
      return;
    }

    this.http.get(`${this.baseUrl}/check-access-code/${this.accessCode}`, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response: any) => {
          this.router.navigate(['/joueur']);
        },
        error: (error: any) => {
          if (error.status === 401) {
            this.errorMessage = 'Code non valide';
          } else if (error.status === 403) {
            this.errorMessage = 'Trop de joueurs utilisent ce code actuellement. Utilisez-en un autre.';
          } else if (error.status === 429) {
            this.errorMessage = 'Tentatives d\'accès trop rapprochées. Réessayez dans 30 secondes.';
          } else {
            this.errorMessage = 'Erreur 154. Réessayez ou contactez l\'assistance.';
          }
        }
      });
  }

  clearErrorMessage(): void {
    this.errorMessage = '';
  }
}
