
<div id="selection-section" preload>
    <div id="left-col" checkPreviousSave [path]="'jeu'">
        <img src="assets/images/home/picto-acces-jeu-blanc.png">
        <p>Accès jeu</p>
    </div>
    <div id="separator">
        <img src="assets/images/home/separateur-vertical.png">
    </div>
    <a routerLink="/activites">
        <div id="right-col">
            <img src="assets/images/home/picto-acces-activites-blanc.png">
            <p>Accès activités</p>
        </div>
    </a>
</div>
<a routerLink="/accueil"><div id="back-button"><img src="assets/images/home/back-button.png"></div></a>