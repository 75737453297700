import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { routes } from './app-routing.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(
      filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe$)
    ).subscribe((event: NavigationEnd) => {
      const currentRoute = this.router.url.split('?')[0];
      const route = routes.find(r => `/${r.path || ''}` === currentRoute);
      if (route && ['accueil', 'administrateur', 'maitre-du-jeu', 'debriefing', 'jeu', 'contact'].includes(route.path || '')) {
        this.addMatomoScript();
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  addMatomoScript() {
    const existingScript = document.querySelector('script[src*="matomo.js"]');
    if (!existingScript) {
      window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
      (function() {
        var u = "https://piwik.reseau-canope.fr/";
        window._paq.push(['setTrackerUrl', u + 'matomo.php']);
        window._paq.push(['setSiteId', '203']);
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = u + 'matomo.js';
        if (s.parentNode) {
          s.parentNode.insertBefore(g, s);
        } else {
          d.head.appendChild(g);
        }
      })();
    }
  }
}
