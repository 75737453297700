import { Directive, HostListener, Input } from '@angular/core';
import { SaveService } from '../save.service';
import { UiService } from '../ui/ui.service';
import { Router } from '@angular/router';
import { GameService } from '../game.service';

@Directive({
  selector: '[checkPreviousSave]',
  standalone: true
})
export class CheckPreviousSaveDirective {
  @Input() path!: string;
  constructor(
    private router: Router,
    private uiService: UiService,
    private saveService: SaveService,
    private gameService: GameService
  ){}

  @HostListener('click')
  onClick(){
    const saveName = this.path === 'jeu' ? 'game' : 'debrief';
    const hasSave = this.saveService.exists(saveName);
    if (hasSave){
      this.uiService.openPopup({ 
        text: 'Une partie est déjà en cours. <br><br>Veux-tu continuer la partie en cours ou en recommencer une nouvelle ?',
        leftButtonText: 'Recommencer',
        rightButtonText: 'Continuer',
        leftButtonCallback: this.restart.bind(this),
        rightButtonCallback: this.continue.bind(this),
      });
    } else {
      this.continue();
    }
  }

  private restart(){
    this.saveService.clearSave();
    this.gameService.clearScenesCache();
    this.router.navigate([this.path]);
  }

  private continue(){
    this.router.navigate([this.path]);
  }

}
