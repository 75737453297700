import { animate, state, style, transition, trigger } from "@angular/animations";

export const fadeIn = trigger('fadeIn', [
    state('visible',
      style({ opacity: 1 })),
    state('hidden',
      style({ opacity: 0 })),
    transition('hidden => visible', [
      animate('1s ease-in-out')
    ]),
    transition('visible => hidden', [
      animate('0.5s ease-in-out')
    ]),
  ])

export const fadeInOnEnter = trigger('fadeInOnEnter', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.75s ease-in-out', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.5s ease-in-out', style({ opacity: 0 }))
    ]),
  ])