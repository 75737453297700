import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment.local';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router'; // Add ActivatedRoute to handle query params

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  public sourcePage = this.globalService.getSourcePage();
  private clientUrl: string = environment.clientUrl; // Use clientUrl from environment
  public agentLogoutUrl: SafeResourceUrl;
  public userLogoutUrl: SafeResourceUrl;
  private redirection: string = ''; // To store the redirection path

  constructor(
    private globalService: GlobalService, 
    private sanitizer: DomSanitizer,
    private http: HttpClient, 
    private userService: UserService, 
    private router: Router, 
    private route: ActivatedRoute  // Inject ActivatedRoute to read query params
  ) {
    this.agentLogoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.agentLogoutUrl);
    this.userLogoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.userLogoutUrl);
  }

  ngOnInit(): void {

    // Retrieve the redirection parameter from the URL
    this.route.queryParams.subscribe(params => {
      this.redirection = params['redirection'] || 'authentification';
    });

    if (this.redirection === 'administrateur') {
      this.router.navigate(['/administrateur']);
    } else if (this.redirection === 'maitre-du-jeu') {
      this.router.navigate(['/maitre-du-jeu']);
    } else {
      this.router.navigate(['/authentification']);
    }

  }

  onAgentClick() {
    if (this.sourcePage === 'administrateur') {
      window.location.href = `${environment.agentCasUrl}${this.clientUrl}/backend/agent-cas-admin`;
    } else if (this.sourcePage === 'maitre-du-jeu') {
      window.location.href = `${environment.agentCasUrl}${this.clientUrl}/backend/agent-cas-master`;
    } else {
      window.location.href = `${environment.agentCasUrl}${this.clientUrl}/backend/agent-cas-admin`;
    }
  }
  
  onUserClick() {
    if (this.sourcePage === 'administrateur') {
      window.location.href = `${environment.userCasUrl}${this.clientUrl}/backend/user-cas-admin`;
    } else if (this.sourcePage === 'maitre-du-jeu') {
      window.location.href = `${environment.userCasUrl}${this.clientUrl}/backend/user-cas-master`;
    } else {
      window.location.href = `${environment.userCasUrl}${this.clientUrl}/backend/user-cas-admin`;
    }
  }
  

}
