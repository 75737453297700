<div id="page">
    <div id="content">
        <h1>Information sur le Traitement des Données Personnelles de Mission Jaurès</h1>
        <h2>Mis à jour le 11/10/2024</h2>
    </div>

    <div id="scrolling-list">
        <p class="title">1. Identité et coordonnées de l’organisme</p>
        <p>
            Nom : RESEAU CANOPE, le responsable de traitement <br>
            Forme juridique : Établissement public à caractère administratif sous tutelle du ministère de l'Éducation Nationale <br>
            Adresse : 1, AV DU FUTUROSCOPE, 86961, Futuroscope CEDEX, France
        </p>
        <p>
            Réseau Canopé informe les utilisateurs sur la façon dont leurs données sont traitées lorsqu’il agit en tant que responsable de traitement.
        </p>

        <p class="title">2. Finalités du traitement de données à caractère personnel</p>
        <ul>
            <li>Développer, administrer et assurer la maintenance de la plateforme</li>
            <li>Exploiter les données de création et de consultation à des fins statistiques anonymes via Matomo et améliorer la navigation de la plateforme</li>
            <li>Gérer l'hébergement des données</li>
            <li>Gérer la sécurité de la plateforme</li>
            <li>Gérer et suivre les demandes des utilisateurs (assistance)</li>
        </ul>
        <p>
            Le recueil des données est obligatoire pour l'exécution du contrat ou les intérêts légitimes poursuivis. En cas de non-fourniture des données nécessaires, certaines fonctionnalités de la plateforme pourraient ne pas être accessibles.
        </p>

        <p class="title">3. Bases légales</p>
        <ul>
            <li>Le contrat</li>
            <li>L’intérêt légitime</li>
        </ul>

        <p class="title">4. Catégorie de données personnelles traitées</p>
        <ul>
            <li>Données de jeu</li>
            <li>Données issues du compte Réseau Canopé (compte créé sur <a href="https://www.reseau-canope.fr">https://www.reseau-canope.fr</a>)</li>
            <li>Données relatives aux cookies</li>
            <li>Données liées au formulaire de contact</li>
        </ul>

        <p class="title">5. Personnes concernées</p>
        <p>
            Le traitement des données concerne les usagers de la plateforme régulièrement connectés via leur compte Réseau Canopé.
        </p>

        <p class="title">6. Durée de conservation des données</p>
        <ul>
            <li>Données de jeu : 1 heure</li>
            <li>Données issues du compte Réseau Canopé : 1 année scolaire (suppression des données au 31 août de chaque année)</li>
            <li>Données liées au formulaire de contact : 12 mois</li>
        </ul>

        <p class="title">7. Cookies</p>
        <p class="subtitle">Cookies de mesure d’audience / mesure statistiques</p>
        <p>
            Ces cookies sont utilisés pour connaître l'affluence et/ou la fréquence de visiteurs présents sur les sites internet édités par Réseau Canopé afin notamment de s'assurer que ce dernier fonctionne rapidement. Ils permettent d’analyser les performances des sites internet édités par Réseau Canopé pour en améliorer le fonctionnement. 
            <br><br>
            Matomo est un outil de mesure d'audience compatible avec le RGPD et les exigences de la CNIL. Ainsi, la réglementation n'exige pas l’obtention du consentement des visiteurs avant de commencer le recueil des données.
        </p>
        <ul>
            <li>SERVERID : Maintenir la sécurité pendant toute votre visite sur la plateforme</li>
            <li>_pk_id : utilisé pour stocker des informations sur l’utilisateur, telles que l’identifiant unique du visiteur. Durée de conservation : 13 mois</li>
            <li>_pk_ses : cookie de courte durée utilisés pour stocker temporairement les données de la visite. Durée de conservation : 30 minutes</li>
            <li>Trateaucitron-rc : gestionnaire de cookies. Durée de conservation : 13 mois</li>
        </ul>

        <p class="title">8. Destinataires des données</p>
        <ul>
            <li>Équipe Réseau Canopé</li>
            <li>La direction des systèmes d’information</li>
            <li>La direction du développement et du marketing</li>
            <li>L’hébergeur pour assurer l’hébergement des données</li>
        </ul>

        <p class="title">9. Prise de décision automatisée</p>
        <p>Le traitement ne prévoit pas de prise de décision automatisée.</p>

        <p class="title">10. Transferts des données hors UE</p>
        <p>
            La plateforme est accessible hors de l'Union Européenne, du fait de sa présence sur Internet.
        </p>

        <p class="title">11. Sécurité</p>
        <p>
            Réseau Canopé respecte le RGPD et la loi informatique et libertés en matière de sécurité et de confidentialité des données. L’établissement met en oeuvre toutes les mesures organisationnelles et techniques nécessaires afin d’assurer la sécurité des traitements de données à caractère personnel et la confidentialité des données qui sont collectées.
        </p>
        <p>
            Réseau Canopé fait appel à un prestataire d’hébergement basé en France et dont les données sont hébergées en France. Le prestataire garantit une application des règles du RGPD par la mise en oeuvre d’une politique de protection des données et une politique de sécurité des systèmes d'information (PSSI).
        </p>

        <p class="title">12. Droits des personnes concernées</p>
        <ul>
            <li>Accès à leurs données</li>
            <li>Rectification de leurs données</li>
            <li>Effacement de leurs données</li>
            <li>Portabilité de leurs données</li>
            <li>Limitation du traitement</li>
            <li>Opposition au traitement</li>
        </ul>

        <p class="title">13. Exercer ses droits</p>
        <p>
            La déléguée à la protection des données (DPO/DPD) de Réseau Canopé est votre interlocutrice pour toute demande d'exercice de vos droits sur ce traitement. Veuillez adresser votre demande concernant l’exercice de vos droits par voie électronique à l’adresse <a href="mailto:dpo@reseau-canope.fr">dpo@reseau-canope.fr</a>
        </p>

        <p class="title">14. Réclamation (plainte) auprès de la CNIL</p>
        <p>
            Si vous estimez, après nous avoir contactés, que vos droits sur vos données ne sont pas respectés, vous pouvez adresser une réclamation (plainte) à la CNIL à l’adresse : <br>
            3 Place de Fontenoy TSA 80715 75334 PARIS CEDEX 07 <br>
            Vous pouvez également vous renseigner sur les démarches à entreprendre sur le site de la CNIL <a href="https://www.cnil.fr/fr">https://www.cnil.fr/fr</a>
        </p>

        <p class="title">15. Politique de confidentialité de Réseau Canopé</p>
        <p>
            Pour plus d’information concernant la politique de confidentialité de Réseau Canopé, nous vous invitons à consulter le lien suivant : <a href="https://www.reseau-canope.fr/politique-de-confidentialite.html" target="_blank">https://www.reseau-canope.fr/politique-de-confidentialite.html</a>
        </p>
    </div>

    <a routerLink="/accueil">
        <div id="back-button">
            <img src="assets/images/home/back-button.png" alt="Back">
        </div>
    </a>
</div>
