import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userSubject = new BehaviorSubject<{ username: string | null, roles: string[] }>({
    username: null, 
    roles: []
  });

  constructor() {}

  setUser(user: { username: string, roles: string[] }) {
    this.userSubject.next(user); // Notify subscribers with the new user data
  }

  getUser(): Observable<{ username: string | null, roles: string[] }> {
    return this.userSubject.asObservable();
  }

  clearUser() {
    this.userSubject.next({ username: null, roles: [] });
  }
}
