// src/environments/environment.ts
export const environment = {
  production: false,
  clientUrl: 'https://mission-jauresdev.reseau-canope.fr',
  apiUrl: 'https://mission-jauresdev.reseau-canope.fr/backend/api',
  apiGetMediaUrl: 'https://mission-jauresdev.reseau-canope.fr/backend/media/get',
  gameId: 1,
  debriefId: 3,
  versionNumber: '1.0.8',
  agentCasUrl: 'https://casval2.reseau-canope.fr/cas/login?service=',
  userCasUrl: 'https://val.reseau-canope.fr/cas-usagers/login?service=',
  agentLogoutUrl: 'https://casval2.reseau-canope.fr/cas/logout',
  userLogoutUrl: 'https://val.reseau-canope.fr/cas-usagers/logout',
  environmentFile: ' - dev'
};
