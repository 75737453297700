<div id="page">
    <div id="ferry">
      <img src="assets/images/home/ferry-cercle-blanc.jpg" alt="Ferry">
    </div>
    <div id="content">
      <h1>Contact</h1>
      <h2>Tous les champs sont obligatoires *</h2>
      <div id="contact-form-container"></div>
    </div>
    <a routerLink="/accueil">
      <div id="back-button">
        <img src="assets/images/home/back-button.png" alt="Back">
      </div>
    </a>
  </div>
  