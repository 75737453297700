import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-game-master',
  templateUrl: './game-master.component.html',
  styleUrls: ['./game-master.component.scss','../admin/admin.component.scss']
})
export class GameMasterComponent implements OnInit {

  constructor(
    private titleService: Title, 
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Espace Maître du jeu');
    this.globalService.setSourcePage('game-master');
  }

}
