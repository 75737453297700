<div class="admin-component">
  <app-header title="ADMINISTRATEUR"></app-header>

  <!-- Template for ROLE_ADMIN -->
  <ng-container *ngIf="userRole === 'admin'">
    <div class="subtitle-section">
      <span>Abonnements :</span>
      <img src="assets/images/admin/line-separator.png">
    </div>

    <div id="admin-subscription-section">
      <!-- Loop through the subscriptions and display the canopeSubscriptionId, canopeProductReference, quantity, date, and schoolYear -->
      <div *ngFor="let subscription of subscriptions">
        <p class="subscription-detail">
          Achat du {{ subscription.date | date: 'dd/MM/yyyy' }} (référence {{ subscription.canopeSubscriptionId }}) :
          {{ subscription.quantity }} licence(s) Education ({{ subscription.canopeProductReference }})
        </p>

        <!-- Display the access codes for each subscription -->
        <div *ngIf="subscription.accessCodes && subscription.accessCodes.length > 0">
          <ul>
            <li *ngFor="let code of subscription.accessCodes">
              <div class="subscription">
                <div class="new-code-button" (click)="confirmNewCode(code.accessCode)">Générer un nouveau code</div>
                <div class="code">{{ code.accessCode }}</div> 
                <div class="connection-count">
                  {{ (code.connectionCount === null || code.connectionCount === 0) ? 'aucune connexion' : (code.connectionCount === 1 ? '1 connexion' : code.connectionCount + ' connexions') }}
                </div>&nbsp;<div class="subscription-start" *ngIf="code.subscriptionStart !== '1000-01-01'">
                  depuis le {{ code.subscriptionStart | date: 'dd/MM/yyyy' }}
                </div>&nbsp;<div class="subscription-end">
                  (Expire le {{ code.subscriptionEnd | date: 'dd/MM/yyyy' }})
                </div>
              </div>
            </li>
          </ul>
        </div>

        <img class="line-separator" src="assets/images/admin/line-separator.png">
      </div>

      <!-- If no subscriptions exist, show a message -->
      <div *ngIf="subscriptions.length === 0">
        Vous n'avez souscrit à aucun abonnement.
      </div>
    </div>
  </ng-container>

  <!-- Template for ROLE_CANOPE (unchanged) -->
  <ng-container *ngIf="userRole === 'canope'">
    <div class="subtitle-section">
      <span class="page-title-canope">Codes Démo (valables 6h) :</span>
      <img src="assets/images/admin/line-separator.png">
    </div>

    <div id="new-demo-code-button" (click)="generateNewCode()">Ajouter un code Démo</div>

    <div id="canope-subscription-section">
      
      <div>

        <div *ngIf="accessCodes.length > 0">
          <ul>
            <li *ngFor="let code of accessCodes">
              <div class="subscription">
                <div class="code">{{ code.accessCode }}</div>
                <div class="code-usage">
                  <div class="code-expiration">Expire à {{ code.subscriptionEnd | date:'HH:mm:ss' }}</div>
                </div>
              </div>
            </li>       
          </ul>
        </div>

        <div *ngIf="accessCodes.length === 0">
          <div id="no-access-code-section">Aucun code valide</div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
