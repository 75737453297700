import { Component } from '@angular/core';

@Component({
  selector: 'app-choose-activity',
  templateUrl: './choose-activity.component.html',
  styleUrls: ['./choose-activity.component.scss']
})
export class ChooseActivityComponent {

  showAlert() {
    alert('En travaux');
  }

}
