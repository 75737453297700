import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopupData } from './popup-data';
import { UiService } from '../ui.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ CommonModule ]
})
export class PopupComponent {
  @Input() data!: PopupData;

  constructor(private uiService: UiService){}

  onClickButton(callback: Function){
    callback();
    this.uiService.closePopup();
  }
}
