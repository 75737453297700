import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.local';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from './../services/user.service';
import { GetSubscriptionsService } from '../services/get-subscriptions.service'; // Import the service

// Interface for AccessCode
interface AccessCode {
  id: number;
  accessCode: string;
  subscriptionStart: string;
  subscriptionEnd: string;
  connectionCount: number;
}

// Interface for Subscription
interface Subscription {
  id: number;
  canopeSubscriptionId: number;
  canopeProductReference: string;
  quantity: number;
  date: string;
  schoolYear: string;
  accessCodes: AccessCode[];
}

// Interface for API Response
interface AdminBoardResponse {
  username: string;
  roles: string[];
  userSubscriptions?: Subscription[];
  demoCodes?: AccessCode[];
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  subscriptions: Subscription[] = []; // Define Subscription[] for subscriptions
  accessCodes: AccessCode[] = []; // Define AccessCode[] for accessCodes
  private apiUrl = environment.apiUrl;
  roles: string[] | null = null;
  userRole = '';

  constructor(
    private titleService: Title,
    private router: Router,
    private globalService: GlobalService,
    private http: HttpClient,
    private userService: UserService, // Inject UserService to get the user roles
    private getSubscriptionsService: GetSubscriptionsService // Inject the GetSubscriptionsService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('Espace Administrateur');
    this.globalService.setSourcePage('administrateur');

    // Call the /api/admin-board endpoint on load
    this.http.get<AdminBoardResponse>(`${this.apiUrl}/admin-board`, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$)) // Corrected the typo here
      .subscribe({
        next: (response) => {

          // Save user details to the UserService
          this.userService.setUser({
            username: response.username,
            roles: response.roles
          });

          // Determine the user's role based on roles array
          if (response.roles.includes('ROLE_ADMIN')) {
            this.userRole = 'admin';
          } else if (response.roles.includes('ROLE_CANOPE')) {
            this.userRole = 'canope';
          }

          // Use userSubscriptions instead of subscriptions
          if (response.userSubscriptions && response.userSubscriptions.length > 0) {
            this.subscriptions = response.userSubscriptions;  // Correct key is userSubscriptions
          } else if (response.demoCodes) {
            this.accessCodes = response.demoCodes;
          }
        },
        error: (error) => {
          if (error.status === 401 || error.status === 403) {
            console.error('Access denied (401 or 403), redirecting to authentification:', error);
            this.router.navigate(['/authentification']); // Redirect for access denied errors
          } else {
            console.error('Other error occurred:', error);
          }
        }
      });
  }

  // Method to generate a new demo code on button click
  generateNewCode(): void {
    const url = `${this.apiUrl}/add-demo-code`;

    // Send a POST request with credentials to add a new demo code
    this.http.post<AccessCode>(url, {}, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (newCode: any) => {

          // Map the fields from the new code to the format used in the template
          const formattedNewCode: AccessCode = {
            id: newCode.id,
            accessCode: newCode.access_code,  // map access_code to accessCode
            subscriptionStart: newCode.subscription_start,  // map subscription_start to subscriptionStart
            subscriptionEnd: newCode.subscription_end,  // map subscription_end to subscriptionEnd
            connectionCount: newCode.connection_count // map connection_count to connectionCount
          };

          // Directly add the newly formatted demo code to the accessCodes array
          this.accessCodes = [...this.accessCodes, formattedNewCode];
        },
        error: (error) => {
          console.error('Error generating new demo code', error);
          this.router.navigate(['/authentification']);
        }
      });
  }

  // Method to get demo codes (refresh the demo codes list)
  getDemoCodes(): void {
    const url = `${this.apiUrl}/api/admin-board`;

    // Fetch the updated demo codes for ROLE_CANOPE
    this.http.get<{ demoCodes: AccessCode[] }>(url, { withCredentials: true })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (response) => {
          if (response.demoCodes) {
            this.accessCodes = response.demoCodes; // Update the list of access codes
          }
        },
        error: (error) => {
          console.error('Error fetching demo codes:', error);
        }
      });
  }

  // Updated confirmNewCode to update access code
  confirmNewCode(fullAccessCode: string): void {
    const isConfirmed = confirm("Attention : l'ancien code cessera de fonctionner aussitôt. Vous voulez vraiment modifier ce code ?");
    if (isConfirmed) {
      // Call the service to update the access code, sending the full access code (e.g., '73-ubmhcz')
      this.getSubscriptionsService.updateAccessCode(fullAccessCode).subscribe({
        next: (response: { newCode: string }) => {
    
          // Update the access code in the local subscriptions or demoCodes array
          if (this.userRole === 'admin') {
            this.subscriptions.forEach(subscription => {
              const foundCode = subscription.accessCodes.find(code => code.accessCode === fullAccessCode);
              if (foundCode) {
                foundCode.accessCode = response.newCode; // Update the local state with the new code
              }
            });
          } else if (this.userRole === 'canope') {
            const foundCode = this.accessCodes.find(code => code.accessCode === fullAccessCode);
            if (foundCode) {
              foundCode.accessCode = response.newCode; // Update the local state with the new code
            }
          }
    
        },
        error: (error) => {
          console.error('Error updating access code', error);
        }
      });
    }
  }

  

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
