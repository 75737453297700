import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, forkJoin, map, of, take, tap } from 'rxjs';

import { environment } from '../../../environments/environment.local';
import { GameObject } from '../objects/game-object/game-object.entity';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

export interface Asset {
  blob: Blob,
  sceneId: number,
  safeUrl: SafeUrl,
  blobUrl: string
}
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private assetCache: Map<string, Asset> = new Map();

  private _gameObjects: GameObject[] = [];

  constructor(
    private http: HttpClient,
    protected sanitizer: DomSanitizer,
  ) { }

  registerGameObject(gameObject: GameObject){
    this._gameObjects.push(gameObject);
  }

  unRegisterGameObject(gameObject: GameObject){
    const index = this._gameObjects.findIndex((gameObj: any) => gameObj.id === gameObject.id);
    if (index !== -1)
      this._gameObjects.slice(index, 1);
  }

  preloadAssets(params: {urls: string[], sceneId: number}): Observable<any> {
    const { urls, sceneId } = params;
    const assets$ = [];
    for (const url of urls)
      assets$.push(this.preloadAsset({url, sceneId}));
    return forkJoin(assets$).pipe(take(1));
  }

  preloadAsset(params: {url: string, sceneId: number}): Observable<any>{
    if (!params.url)
      return EMPTY;
    const { url, sceneId } = params;

    return this.getAssetSource(url, sceneId).pipe(
      map((asset: Asset) => asset.blob)
    );
  }

  getAssetSource(url: string, sceneId: number): Observable<Asset>{
    const cache = this.getPreloadedAsset(url);
    if (cache)
      return of(cache)
    else 
      return this.http.get(`${environment.apiGetMediaUrl}/${url}`, { responseType: 'blob', withCredentials: true }).pipe(
        map((blob) => {
          const blobUrl = URL.createObjectURL(blob)
          const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
          const asset = { blob, sceneId, safeUrl, blobUrl };
          this.assetCache.set(url, asset);
          return asset;
        }));
  }

  getAssetBlobUrl(url: string, sceneId: number): Observable<SafeUrl>{
    return this.getAssetSource(url, sceneId).pipe(
      map((asset: Asset) => asset.safeUrl)
    );
  }

  getPreloadedAsset(url: string): Asset | undefined{
    return this.assetCache.get(url);
  }

  getPreloadedAssetBlobUrl(url: string): SafeUrl {
    const asset = this.getPreloadedAsset(url);
    if (!asset)
      throw new Error(`Asset ${url} is not preloaded yet`);
    return asset.safeUrl;
  }

  unloadAllExcept(sceneId: number){
    for (let [key, value] of this.assetCache) {
      if (![sceneId, -1].includes(value.sceneId))// -1 is for inventory items
          this.assetCache.delete(key);
      }
    }
}
