import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { ChooseGameComponent } from './pages/choose-game/choose-game.component';
import { ChooseActivityComponent } from './pages/choose-activity/choose-activity.component';
import { CodeControlComponent } from './pages/code-control/code-control.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { SessionService } from './services/session.service';
import { inject } from '@angular/core';
import { GameConsole } from './logger/logger';

const sessionResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const sessionService = inject(SessionService);
  const router = inject(Router);
  const sourcePage = route.data['sourcePage'];
  GameConsole.log(`Resolving session for page: ${sourcePage}`);

  return sessionService.checkSession(sourcePage).pipe(
    tap(isValid => {
      GameConsole.log(`Session validity for ${sourcePage}: ${isValid}`);
      if (!isValid) {
        if (sourcePage === 'administrateur' || sourcePage === 'maitre-du-jeu') {
          GameConsole.log('Redirecting to /authentification');
          router.navigate(['/authentification']);
        } else if (sourcePage === 'joueur') {
          GameConsole.log('Redirecting to /code');
          router.navigate(['/code']);
        }
      }
    }),
    catchError(error => {
      GameConsole.log(`Error checking session for ${sourcePage}:`, error);
      router.navigate(['/authentification']);
      return of(false);
    }),
    map(isValid => isValid)
  );
};

export const routes: Routes = [
  { path: '', redirectTo: '/accueil', pathMatch: 'full' },
  { path: 'accueil', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  {
    path: 'administrateur',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    resolve: { session: sessionResolver },
    data: { sourcePage: 'administrateur' }
  },
  {
    path: 'maitre-du-jeu',
    loadChildren: () => import('./game-master/game-master.module').then(m => m.GameMasterModule),
    resolve: { session: sessionResolver },
    data: { sourcePage: 'maitre-du-jeu' }
  },
  {
    path: 'debriefing',
    loadChildren: () => import('./game/game.module').then(m => m.GameModule),
    resolve: { session: sessionResolver },
    data: { sourcePage: 'joueur' }
  },
  {
    path: 'jeu',
    loadChildren: () => import('./game/game.module').then(m => m.GameModule),
    resolve: { session: sessionResolver },
    data: { sourcePage: 'joueur' }
  },
  { path: 'authentification', component: AuthenticationComponent },
  {
    path: 'joueur',
    component: ChooseGameComponent,
    resolve: { session: sessionResolver },
    data: { sourcePage: 'joueur' }
  },
  {
    path: 'activites',
    component: ChooseActivityComponent,
    resolve: { session: sessionResolver },
    data: { sourcePage: 'joueur' }
  },
  { path: 'faq', component: FaqComponent },
  { path: 'code', component: CodeControlComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: '/accueil' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
