import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.isLoading
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        this.loading = v;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
