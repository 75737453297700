<div id="page">
    <div id="content">
        <h1>FAQ</h1>
        <h2>Pour toute autre question, utilisez le formulaire de contact.</h2>
    </div>

    <div class="question"><div class="list-icon"><img src="assets/images/faq/list-icon.png"></div>Sur quels supports le jeu est-t-il disponible ?</div>
    <div class="answer">Le jeu est multi-support, il est donc possible d’y jouer sur PC et tablettes. Le PC est tout de même recommandé pour un confort de jeu optimal.</div>

    <div class="question"><div class="list-icon"><img src="assets/images/faq/list-icon.png"></div> Comment acheter le jeu et y accéder ?</div>
    <div class="answer">Pour acheter le jeu rendez-vous sur : <a href="https://www.reseau-canope.fr/service/mission-jaures-numerique.html" target="_blank">https://www.reseau-canope.fr/service/mission-jaures-numerique.html</a>. 
        <br><br>Il suffira ensuite de vous connecter grâce au compte Réseau Canopé, via lequel vous avez procédé à l’achat, sur https://mission-jaures.reseau-canope.fr puis de cliquer sur “Administrateur”. C’est sur cet espace “Administrateur” que vous trouverez le(s) code(s) d’accès au jeu, que vous devrez distribuer à vos joueurs.</div>

    <div class="question"><div class="list-icon"><img src="assets/images/faq/list-icon.png"></div> Comment je crée un compte Réseau Canopé ?</div>
    <div class="answer">Pour toute(s) question(s) relative à la création de compte Réseau Canopé suivez ce lien : <a href="https://www.reseau-canope.fr/besoin-daide/mon-compte.html" target="_blank">https://www.reseau-canope.fr/besoin-daide/mon-compte.html</a></div>

    <div class="question"><div class="list-icon"><img src="assets/images/faq/list-icon.png"></div> Est ce qu’il est nécessaire d’avoir un compte pour jouer au jeu ?</div>
    <div class="answer">Pour jouer au jeu, il n’est pas nécessaire d’avoir un compte. </div>

    <div class="question"><div class="list-icon"><img src="assets/images/faq/list-icon.png"></div> Que faire si je n’ai pas de code d’accès dans mon espace administrateur ?</div>
    <div class="answer">Si vous n’avez pas de code d’accès dans votre espace administrateur, vérifiez que le compte Réseau Canopé avec lequel vous vous êtes connecté est bien celui avec lequel vous avez procédé à l’achat.
        <br><br>
        Si cela ne fonctionne toujours pas, rendez-vous sur <a href="https://www.reseau-canope.fr" target="_blank">https://www.reseau-canope.fr</a> et dans “mon compte” cliquez sur “ma bibliothèque”. Si vous avez effectivement acheté le jeu, il doit être présent dans cette rubrique.
        <br><br>
        En cas de problème persistant, n’hésitez pas à nous contacter via le formulaire de contact.</div>

    <div class="question"><div class="list-icon"><img src="assets/images/faq/list-icon.png"></div> Que faire si le jeu plante ou ne se lance pas ?</div>
    <div class="answer"> Si le jeu plante ou ne se lance pas vous pouvez : 
        <br>
        -	Rafraîchir la page
        <br>
        -	Changer de navigateur internet
        <br>
        -	Changer de machine
        <br><br>
        En cas de problème persistant, n’hésitez pas à nous contacter via le formulaire de contact.</div>

    <div class="question"></div>
    <div class="answer"></div>

    <div class="question"></div>
    <div class="answer"></div>

    <a routerLink="/accueil"><img src="assets/images/home/back-button.png" alt="Back"></a>
</div>