<div id="page">
    <div id="content">
        <h1>Mentions légales</h1>
    </div>

    <div id="scrolling-list">
        
        <p>En travaux</p>

    </div>

    <a routerLink="/accueil">
        <div id="back-button">
            <img src="assets/images/home/back-button.png" alt="Back">
        </div>
    </a>
</div>
