export class Save {
    constructor(
        public createdAt: number,
        public timer: number,
        public pastEvents: number[],
        public inventory: { [key: string]: number },
        public mainScene?: number,
    ){}

    toString(){
        return JSON.stringify(this);
    }
}