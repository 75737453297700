import { Component } from '@angular/core';
import { environment } from 'src/environments/environment.local';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  versionNumber: string = environment.versionNumber;
}
