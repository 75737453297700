<div class="admin-component">
  <app-header title="ADMINISTRATEUR"></app-header>

  <div *ngIf="userRole === 'ROLE_ADMIN'; else canopeTemplate">
    <div class="subtitle-section">
      <span>Abonnements :</span>
      <img src="assets/images/admin/line-separator.png">
    </div>

    <div id="subscription-section">
      <div *ngIf="subscriptions.length === 0">
        <div id="no-subscription-section">Vous n'avez souscrit à aucun abonnement</div>
      </div>

      <div *ngIf="subscriptions.length > 0">
        <div *ngFor="let subscription of subscriptions">
          <p class="subscription-detail">
            Achat du {{ subscription.date | date:'dd/MM/yyyy' }} (référence {{ subscription.canopeSubscriptionId }}) : {{ subscription.quantity }} licence(s) Education ({{ subscription.canopeProductReference }})
          </p>
          <div *ngIf="subscription.accessCodes && subscription.accessCodes.length > 0">
            <ul>
              <li *ngFor="let code of subscription.accessCodes">
                <div class="subscription">
                  <div class="new-code-button" (click)="confirmNewCode(code.id)">Générer un nouveau code</div>
                  <div class="code">{{ code.accessCode }}</div>
                  <div class="code-usage">
                    <div class="code-log">
                      <ng-container *ngIf="code.subscriptionStart === '1000-01-01 00:00:00'; else usedCode">
                        {{ code.connectionCount | i18nPlural: connectionCountMapping }}
                      </ng-container>
                      <ng-template #usedCode>
                        {{ code.connectionCount | i18nPlural: connectionCountMapping }} depuis le {{ code.subscriptionStart | date:'dd/MM/yyyy' }}
                      </ng-template>
                    </div>
                    <div class="code-expiration">(Expire le {{ code.subscriptionEnd | date:'dd/MM/yyyy' }})</div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <img class="line-separator" src="assets/images/admin/line-separator.png">
        </div>
      </div>
    </div>
  </div>

  <ng-template #canopeTemplate>
    <div class="subtitle-section">
      <span class="page-title-canope">Codes Démo (valables 6h) :</span>
      <img src="assets/images/admin/line-separator.png">
    </div>

    <div id="canope-subscription-section">
      <div>
        <div class="new-code-button" (click)="addDemoCode()">Ajouter un code Démo</div>

        <div *ngIf="accessCodes.length > 0">
          <ul>
            <li *ngFor="let code of accessCodes">
              <div class="subscription">
                <div class="code">{{ code.accessCode }}</div>
                <div class="code-usage">
                  <div class="code-expiration">Expire à {{ code.subscriptionEnd | date:'HH:mm:ss' }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div *ngIf="accessCodes.length === 0">
          <div id="no-access-code-section">Aucun code valide</div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
