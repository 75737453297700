import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PopupData } from './popup/popup-data';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  private _popup$ = new BehaviorSubject<PopupData | null>(null);
  public readonly popup$ = this._popup$.asObservable();

  constructor() { }

  openPopup(data: PopupData){
    this._popup$.next(data);
  }

  closePopup(){
    this._popup$.next(null);
  }
}
