import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { environment } from '../../../environments/environment.local';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {

  public sourcePage = this.globalService.getSourcePage();
  private baseUrl: string = '';
  public agentLogoutUrl: SafeResourceUrl;
  public userLogoutUrl: SafeResourceUrl;

  constructor(private globalService: GlobalService, private sanitizer: DomSanitizer) {
    this.agentLogoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.agentLogoutUrl);
    this.userLogoutUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.userLogoutUrl);
  }

  ngOnInit(): void {
    this.sourcePage = this.globalService.getSourcePage() || 'player';
    this.baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    if (window.location.port) {
      this.baseUrl += `:${window.location.port}`;
    }
    this.baseUrl += '/';
  }

  onAgentClick() {
    if (this.sourcePage === 'administrateur') {
      window.location.href = `${environment.agentCasUrl}${this.baseUrl}backend/agent-cas-admin`;
    } else {
      window.location.href = `${environment.agentCasUrl}${this.baseUrl}backend/agent-cas-master`;
    }
  }

  onUserClick() {
    if (this.sourcePage === 'administrateur') {
      window.location.href = `${environment.userCasUrl}${this.baseUrl}backend/user-cas-admin`;
    } else {
      window.location.href = `${environment.userCasUrl}${this.baseUrl}backend/user-cas-master`;
    }
  }
}
