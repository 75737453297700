import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private totalRequests = 0;
  private readonly excludedPaths = ['/jeu', '/debriefing'];

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentPath = window.location.pathname;
    const shouldShowLoader = !this.excludedPaths.some(path => currentPath.includes(path));

    if (shouldShowLoader) {
      this.totalRequests++;
      this.loaderService.show();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (shouldShowLoader) {
          this.totalRequests--;
          if (this.totalRequests === 0) {
            this.loaderService.hide();
          }
        }
      })
    );
  }
}
