import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalService } from '../services/global.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.local';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';  // Import Router to handle navigation

@Component({
  selector: 'app-game-master',
  templateUrl: './game-master.component.html',
  styleUrls: ['./game-master.component.scss', '../admin/admin.component.scss']
})
export class GameMasterComponent implements OnInit {

  clientUrl: string = '';

  constructor(
    private titleService: Title, 
    private globalService: GlobalService,
    private http: HttpClient, 
    private userService: UserService, // Inject UserService
    private router: Router  // Inject Router for navigation
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Espace Maître du jeu');
    this.globalService.setSourcePage('maitre-du-jeu');
    this.clientUrl = environment.clientUrl;

    // Send request to get user details
    this.http.get<{ username: string, roles: string[] }>(`${environment.apiUrl}/get-user-details`, { withCredentials: true })
      .subscribe({
        next: (response) => {
          // Update UserService with user details
          this.userService.setUser({ username: response.username, roles: response.roles });
        },
        error: (error) => {
          // Handle access denied errors like 401 Unauthorized or 403 Forbidden
          if (error.status === 401 || error.status === 403) {
            console.error('Access denied, redirecting to authentification');
            this.router.navigate(['/authentification']);
          } else {
            console.error('Error fetching user details:', error);
          }
        }
      });
  }
}
