import { environment } from "src/environments/environment.local";

export class AvifService {
  static avifSupported: boolean | null = null;

  static checkAvifSupport() {
    const img = new Image();
    img.src = 'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
    
    img.onload = () => {
      this.avifSupported = true;
    };

    img.onerror = (error) => {
      this.avifSupported = false;
    };
  }

  static isAvifSupported(): boolean {
    const avifAllowed = environment.avifAllowed === true;
    return this.avifSupported === true && avifAllowed === true;
  }

  static toAvif(url: string): string {
    return url.replace(/\.(png|jpg)$/, '.avif');
  }

  static toAvifMany(urls: string[]): string[] {
    return urls.map((url: string) => url.replace(/\.(png|jpg)$/, '.avif'));
  }
}
