
<div id="selection-section" preload>
    <div id="left-col" checkPreviousSave [path]="'debriefing'">
        <img src="assets/images/home/picto-acces-activites-blanc.png">
        <p>Toutes les activités</p>
    </div>
    <div id="separator">
        <img src="assets/images/home/separateur-vertical.png">
    </div>
    <a (click)="showAlert()">
        <div id="right-col">
            <img src="assets/images/home/picto-acces-videos-blanc.png">
            <p>Seulement les vidéos</p>
        </div>
    </a>
</div>
<a routerLink="/joueur"><div id="back-button"><img src="assets/images/home/back-button.png"></div></a>