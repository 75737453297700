export class GameEvent {
    public readonly id: number;
    public readonly type: GameEventTypes;
    public readonly trigger: GameEventTriggers;
    public positionInSequence: number;
    public readonly content: any;
    [key: string]: any;

    constructor(
        data: {
            id: number,
            eventType: string,
            eventTrigger: string,
            positionInSequence: number,
            attributeValues: any[]
        }
    ) {
        this.id = data.id;
        this.type = data.eventType as GameEventTypes;
        this.trigger = data.eventTrigger as GameEventTriggers;
        this.positionInSequence = data.positionInSequence;
        this.getAttributes(data.attributeValues);
    }

    private getAttributes(attributes: any){
        for (let attribute of attributes){
            if (attribute.value === null) continue;
            if (this.shouldMakeAlist(attribute))
                this[attribute.attributeName] = attribute.value.split(',');
            else if (attribute.value === 'true')
                this[attribute.attributeName] = true;
            else if (attribute.value === 'false')
                this[attribute.attributeName] = false;
            else
                this[attribute.attributeName] = attribute.value;
        }
    }

    private shouldMakeAlist(attribute: any): boolean {
        return (typeof attribute.value === 'string' && attribute.value.includes(',') && attribute.attributeName !== 'text') || ['lockedUntil', 'lockedAfter'].includes(attribute.attributeName);
    }

    setScene(inScene: number){
        this['inScene'] = inScene;
    }

    get canBeTriggeredTwice(){
        return ['key', 'inventory', 'inputValidation'].includes(this.type);
    }
}

export type GameEventTypes =
    'audio' |
    'animationCss' |
    'animationSprite' |
    'closeScene' |
    'grab'|
    'hideObject'|
    'inputValidation' |
    'inventory' |
    'image' |
    'key' |
    'leaveObject' |
    'nextScene' |
    'openScene' |
    'restartGame'|
    'revealObjectsPositions' |
    'reward' |
    'showObject' |
    'startTimer' |
    'stopTimer' |
    'startAudio' |
    'stopAnimation' |
    'text' |  
    'updateObjectUses';

export type GameEventTriggers =
    'checked' | 
    'click' | 
    'drop' | 
    'enterScene' | 
    'exitScene' | 
    'idle' | 
    'inputNotValidated'|
    'inputValidated' | 
    'mouseEnter' |
    'mouseLeave' |
    'parentEvent'; 