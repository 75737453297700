import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, catchError, concatMap, forkJoin, map, of, take, tap, timer } from 'rxjs';

import { Game } from './game.entity';
import { environment } from 'src/environments/environment.local';
import { LoadingService } from './loading/loading.service';
import { Scene } from './scene/scene.entity';
import { GameEvent } from './event/game-event.entity';
import { InputService } from './input.service';
import { Router } from '@angular/router';
import { Timer } from './timer/timer';
import { UiService } from './ui/ui.service';
import { pausePopup } from './ui/popup/popup-data';
import { AudioService } from './objects/audio/audio.service';
import { AvifService } from './avif.service';

@Injectable({
  providedIn: 'root'
})
export class GameService {
  public readonly DEFAULT_COUNTDOWN = 2400;

  private _game$ = new BehaviorSubject<Game | null>(null);
  public readonly game$ = this._game$.asObservable();

  private _mainScene$ = new BehaviorSubject<Scene | null>(null);
  public readonly mainScene$ = this._mainScene$.asObservable();
  get mainScene() { return this._mainScene$.getValue() };

  private _activeScenes$ = new BehaviorSubject<Scene[]>([]);
  public readonly activeScenes$ = this._activeScenes$.asObservable();

  private _countdown$ = new BehaviorSubject<number>(-1);
  public readonly countdown$ = this._countdown$.asObservable();

  private _timerStarted$ = new BehaviorSubject<boolean>(false);
  public readonly timerStarted$ = this._timerStarted$.asObservable();

  private _pause$ = new BehaviorSubject<boolean>(false);
  public readonly pause$ = this._pause$.asObservable();

  private scenesCache: Map<number, Scene> = new Map();
  private gameCache!: Game;
  private debriefCache!: Game;
  private _mainTimer = new Timer(1000);
  public readonly mainTimer = this._mainTimer;

  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private inputService: InputService,
    private uiService: UiService,
    private audioService: AudioService,
    private router: Router
  ) { 
    AvifService.checkAvifSupport();
  }

  getGame(sceneId?: number): Observable<Scene> { 
    let game$;
    const isDebrief = window.location.pathname.includes('debriefing') || window.location.pathname.includes('activites');
    const gameId = isDebrief ? environment.debriefId : environment.gameId;
    this._activeScenes$.next([]);
    this._mainScene$.next(null);
    if (this.gameCache && !isDebrief)
      game$ = of(this.gameCache);
    else if (this.debriefCache && isDebrief)
      game$ = of(this.debriefCache);
    else {
      game$ = this.http.get(`${environment.apiUrl}/load-game/${gameId}`, { withCredentials: true }).pipe(
        catchError((error) => this.onSessionExpired(error)),
        map((backendGame: any) => new Game(
          backendGame.id, 
          backendGame.name, 
          this.getScenesIds(backendGame.scenes),
          backendGame.has_timer === 1 || true,
          backendGame.timer_duration || this.DEFAULT_COUNTDOWN
        )),
        tap((game: Game) => {
          if (isDebrief) this.debriefCache = game;
          else this.gameCache = game;
        }),
      );
    }
    return game$.pipe(
      tap((game: Game) => this._game$.next(game)),
      concatMap((game: Game) => this.getScene(sceneId || game.scenes[0]))
    );
  }

  private getScenesIds(scenes: any): number[] {
    const scenesList = Object.values(scenes);
    scenesList.sort((a: any, b: any) => a.positionInSequence - b.positionInSequence);
    const ids = scenesList.map((scene: any) => scene.id);
    return ids;
  }

  private onSessionExpired(error: HttpErrorResponse){
    if ([401, 403].includes(error.status)){
      this.uiService.openPopup({ 
        text: "Votre session a expiré. <br><br>Vous allez être redirigé(e) vers l'accueil.<br><br> Vous pourrez générer un nouveau code d'accès ou réutiliser ceux qui sont encore valides.",
        rightButtonText: "Retour à l'accueil",
        rightButtonCallback: this.goHome.bind(this)
      })
    }
    return EMPTY;
  }

  private goHome(){
    this.router.navigate(['home']);
  }

  getScene(id: number, sourceEvent?: GameEvent): Observable<Scene> {
    return this._getScene(id, sourceEvent).pipe(
      tap((scene: Scene) => this._activeScenes$.next([...new Set([...this._activeScenes$.getValue(), scene])])),
      tap((scene: Scene) => this.updateMainScene(scene)),
    );
  }

  private _getScene(id: number, sourceEvent?: GameEvent): Observable<Scene> {
    let scene$;
    const cache = this.scenesCache.get(id);
    if (cache){
      if (sourceEvent) // allows to pass some of the events attributes to the scene
        cache.setAttributes(sourceEvent); // in order to reuse some scenes
      scene$ = of(cache);
    }
    else
      scene$ = this.http.get<Scene>(`${environment.apiUrl}/load-scene/${id}`, { withCredentials: true }).pipe(
        catchError((error) => this.onSessionExpired(error)),
        map((backendScene: any) => new Scene(backendScene, sourceEvent, this.inputService)),
        tap((scene: Scene) => this.scenesCache.set(scene.id, scene)),
      );
      return scene$;
  }

  private updateMainScene(scene: Scene){
    const mainScenes = this._game$.getValue()?.scenes;
    if (mainScenes && mainScenes.includes(scene.id)){
      scene.timer.start();
      this._mainScene$.next(scene);
    }
  }

  preloadNextScene(){
    const scenes = this._game$.getValue()?.scenes;
    if (!scenes)
      return EMPTY;
    const sceneIndex = scenes.findIndex((sceneId) => sceneId === this._mainScene$.getValue()?.id);
    if (sceneIndex === -1 || sceneIndex >= scenes.length - 1)
      return EMPTY;
    return of(true).pipe(
      take(1),
      concatMap(() => this.preloadScene(scenes[sceneIndex + 1]))
    );
  }

  preloadScene(sceneId: number, sourceEvent?: GameEvent): Observable<any>{
    return this._getScene(sceneId, sourceEvent);
  }

  preLoadSubScenes(scene: Scene){
    const scenes$: Observable<Scene>[] = [];
    const subScenes = this.gatherSubScenes(scene);
    for (let subScene of subScenes){
      if (subScene === null) continue;
      const subscene$ = this.preloadScene(Number(subScene)).pipe(
        concatMap((scene) => this.loadingService.preloadAssets({ urls: scene.files, sceneId: scene.id }).pipe(take(1)))
      )
      scenes$.push(subscene$);
    }
    return forkJoin(scenes$).pipe(take(1));
  }

  private gatherSubScenes(scene: Scene): number[]{
    const sceneIds = [];
    for (let events of scene.gameObjects.map(obj => obj.events)){
        const openSceneEvents = events.filter((event) => event.type === 'openScene').map(event => event['sceneId']);
        sceneIds.push(...openSceneEvents);
    }  
    return sceneIds;
  }

  closeScene(id: number) {
    const scenes = this._activeScenes$.getValue();
    const sceneIndex = scenes.findIndex((scene) => scene.id === id);
    if (sceneIndex !== -1)
      scenes.splice(sceneIndex, 1);
    this._activeScenes$.next(scenes);
  }

  getNextSceneId(){
    const scenes = this._game$.getValue()?.scenes || [];
    const mainSceneId = this._mainScene$.getValue()?.id || -1;
    const currentSceneIndex = scenes.findIndex((id) => id === mainSceneId);
    return currentSceneIndex < scenes.length - 1 ? scenes[currentSceneIndex + 1] : null;
  }

  getTopSceneId(){
    const activeScenes = this._activeScenes$.getValue().filter((scene) => scene.fullscreen);
    return activeScenes.length > 0 ? activeScenes[activeScenes.length - 1].id : -1;
  }

  triggerNextScene(){
    const nextSceneId = this.getNextSceneId();
    if (!nextSceneId)
      return EMPTY;
    const activeScenes = this._activeScenes$.getValue().map((scene) => scene.id);
    activeScenes.forEach((scene) => this.closeScene(scene));
    this.loadingService.unloadAllExcept(nextSceneId);
    return this.getScene(nextSceneId);
  }

  startTimer(from?: number){
    const game = this._game$.getValue();
    if (!game?.hasTimer) return EMPTY;
    this._timerStarted$.next(true);
    this._mainTimer.start(from);
    return this._mainTimer.timer$.pipe(
      tap((time: number) => this._countdown$.next(game.timerDuration - time))
    );
  }

  restart(){
    this._timerStarted$.next(false);
    this._mainScene$.next(null);
    this._activeScenes$.next([]);
    this.loadingService.unloadAllExcept(-1);
    this.router.navigate(['joueur']);
  }

  clearScenesCache(){
    this.scenesCache.clear();
  }

  togglePause(){
    const pause = !this._pause$.getValue();
    this._pause$.next(pause);
    this.audioService.togglePause();
    if (pause){
      this._mainTimer.pause();
      this.uiService.openPopup(pausePopup(this.togglePause.bind(this)));
    }
    else
      this._mainTimer.start();
  }

  toggleFullscreen(){
    if (!document.fullscreenElement)
      document.documentElement.requestFullscreen();
    else 
      document.exitFullscreen ? document.exitFullscreen() : (document as any).webkitExitFullscreen();
  }
}