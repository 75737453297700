<header>
  <nav>
    <div id="user-section">
      <div id="title">{{ title }}</div>
      <div id="user">
        {{ username }}
      </div>
    </div>
    <div id="button-section">
      <a routerLink="/accueil">
        <div id="home-button"><span>Retour accueil</span> <img src="assets/images/admin/home-button.png"></div>
      </a>
      <a routerLink="/faq">
        <div id="help-button"><span>FAQ</span> <img src="assets/images/admin/help-button.png"></div>
      </a>
      <a href="https://www.reseau-canope.fr/service/mission-jaures-numerique.html" target="_blank">
        <div id="subscribe-button"><span>Acheter des licences</span> <img src="assets/images/admin/subscribe-button.png"></div>
      </a>
      <div id="logout-button" (click)="signOut()"><span>Déconnexion</span> <img src="assets/images/admin/logout-button.png"></div>
    </div>
  </nav>
</header>
<div id="hero-image"><img src="assets/images/admin/jules-ferry.png"></div>
